const initialState = {
  listDivisi: [],
};

const divisiReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLDIVISI":
      return {
        ...state,
        listDivisi: data,
      };
    default:
      return state;
  }
};
export default divisiReducer;
