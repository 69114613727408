const initialState = {
  listProduk: [],
  listProdukUpgrade: [],
  listProdukPo: [],
  listProdukUpgradePo: [],
  totalProdukPo: 0,
};

const produkReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLPRODUK":
      return {
        ...state,
        listProduk: data,
      };
    case "SET_ALLPRODUKUPGRADE":
      return {
        ...state,
        listProdukUpgrade: data,
      };
    case "SET_ALLPRODUKPO":
      return {
        ...state,
        listProdukPo: data,
      };
    case "SET_ALLPRODUKUPGRADEPO":
      return {
        ...state,
        listProdukUpgradePo: data,
      };
    case "SET_TOTALPRODUKPO":
      return {
        ...state,
        totalProdukPo: data,
      };
    default:
      return state;
  }
};
export default produkReducer;
