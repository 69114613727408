import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function orderProduk(data) {
  try {
    const response = await baseAxios.post("/orderWeb/addOrder", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getOrder(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/orderWeb/getOrder?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLORDERPRODUK", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function cetakSo(data) {
  try {
    const response = await baseAxios(`/orderWeb/cetakSoPdf?idSo=${data.idSo}`, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cancelSo(data) {
  try {
    const response = await baseAxios.post("/orderWeb/cancelSo", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
