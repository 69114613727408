const initialState = {
  listOrderProduk: [],
};

const orderReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLORDERPRODUK":
      return {
        ...state,
        listOrderProduk: data,
      };
    default:
      return state;
  }
};
export default orderReducer;
