const initialState = {
  responLogin: null,
  profile: null,
};

const authReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_IDENTITY":
      return {
        ...state,
        responLogin: data,
      };
    case "SET_PROFILE":
      return {
        ...state,
        profile: data,
      };
    default:
      return state;
  }
};

export default authReducer;
