import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getDivisi(dispatch, data, history) {
  try {
    const response = await baseAxios("/divisiWeb/getDivisi", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLDIVISI", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahDivisi(data) {
  try {
    const response = await baseAxios.post("/divisiWeb/addDivisi", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateDivisi(data) {
  try {
    const response = await baseAxios.patch("/divisiWeb/updateDivisi", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteDivisi(data) {
  try {
    const response = await baseAxios.patch("/divisiWeb/deleteDivisi", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
