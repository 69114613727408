import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getKaryawan,
  tambahKaryawan,
  importKaryawan,
  getDivisi,
  getJabatan,
  getRole,
  getKaryawanList,
  editDataKaryawan,
  deleteDataKaryawan,
} from "stores";
import "./Pagination.css";

function Karyawan() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const divisis = useSelector((state) => state.divisiReducer);
  const jabatans = useSelector((state) => state.jabatanReducer);
  const roles = useSelector((state) => state.roleReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listKaryawan, setListKaryawan] = React.useState([]);
  const [idUser, setIdUser] = React.useState("");
  const [namaKaryawan, setNamaKaryawan] = React.useState("");
  const [namaPanggilan, setNamaPanggilan] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [nik, setNik] = React.useState("");
  const [tempatLahir, setTempatLahir] = React.useState("");
  const [tanggalLahir, setTanggalLahir] = React.useState("");
  const [noTelp, setNoTelp] = React.useState("");
  const [awalKerja, setAwalKerja] = React.useState("");
  const [jabatan, setJabatan] = React.useState("");
  const [divisi, setDivisi] = React.useState("");
  const [atasan, setAtasan] = React.useState("");
  const [role, setRole] = React.useState("");
  const [alamatKtp, setAlamatKtp] = React.useState("");
  const [rtKtp, setRtKtp] = React.useState("");
  const [rwKtp, setRwKtp] = React.useState("");
  const [kelurahanKtp, setKelurahanKtp] = React.useState("");
  const [kecamatanKtp, setKecamatanKtp] = React.useState("");
  const [kotaKtp, setKotaKtp] = React.useState("");
  const [provinsiKtp, setProvinsiKtp] = React.useState("");
  const [alamatDomisili, setAlamatDomisili] = React.useState("");
  const [rtDomisili, setRtDomisili] = React.useState("");
  const [rwDomisili, setRwDomisili] = React.useState("");
  const [kelurahanDomisili, setKelurahanDomisili] = React.useState("");
  const [kecamatanDomisili, setKecamatanDomisili] = React.useState("");
  const [kotaDomisili, setKotaDomisili] = React.useState("");
  const [provinsiDomisili, setProvinsiDomisili] = React.useState("");
  const [resign, setResign] = React.useState("");
  const [tglResign, setTglResign] = React.useState("");
  const [menu, setMenu] = React.useState("");

  const [modal, setModal] = React.useState(false);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [excel, setExcel] = React.useState([]);
  const [modalImport, setModalImport] = React.useState(false);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [detailKaryawan, setDetailKaryawan] = React.useState(null);
  function handleFileChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setExcel(selectedFiles);
  }
  const removeExcel = (index) => {
    setExcel(excel.filter((_, i) => i !== index));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const [listAtasan, setListAtasan] = React.useState([]);
  const [listDivisi, setListDivisi] = React.useState([]);
  const [listJabatan, setListJabatan] = React.useState([]);
  const [listRole, setListRole] = React.useState([]);

  React.useEffect(() => {
    let tmpKaryawan = [];
    let tmpDivisi = [];
    let tmpJabatan = [];
    let tmpRole = [];

    karyawan.listKaryawanDropDown.data &&
      karyawan.listKaryawanDropDown.data.map((val) => {
        tmpKaryawan.push({ value: val.idUser, label: val.namaLengkap });
      });
    divisis.listDivisi.data &&
      divisis.listDivisi.data.map((val) => {
        tmpDivisi.push({ value: val.idDivisi, label: val.namaDivisi });
      });
    jabatans.listJabatan.data &&
      jabatans.listJabatan.data.map((val) => {
        tmpJabatan.push({ value: val.idJabatan, label: val.namaJabatan });
      });
    roles.listRole.data &&
      roles.listRole.data.map((val) => {
        tmpRole.push({ value: val.idRole, label: val.namaRole });
      });

    setListAtasan(tmpKaryawan);
    setListDivisi(tmpDivisi);
    setListJabatan(tmpJabatan);
    setListRole(tmpRole);
  }, [
    karyawan.listKaryawanDropDown,
    divisis.listDivisi,
    jabatans.listJabatan,
    roles.listRole,
  ]);

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + karyawan.listKaryawan.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setModalDetail(true);
                  setDetailKaryawan(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ||
              (auth.responLogin && auth.responLogin.role === "Admin") ? (
                <div
                  onClick={() => {
                    setModalEdit(true);
                    setIdUser(row.idUser);
                    setNamaKaryawan(row.namaLengkap);
                    setNamaPanggilan(row.namaPanggilan);
                    setEmail(row.email);
                    setNik(row.nik);
                    setTempatLahir(row.tempatLahir);
                    setTanggalLahir(row.tanggalLahir);
                    setNoTelp(row.noTelp);
                    setAwalKerja(row.awalBekerja);
                    setJabatan(
                      row.jabatan.length === 0
                        ? ""
                        : {
                            value: row.jabatan[0].idJabatan,
                            label: row.jabatan[0].namaJabatan,
                          }
                    );
                    setDivisi(
                      row.divisi.length === 0
                        ? ""
                        : {
                            value: row.divisi[0].idDivisi,
                            label: row.divisi[0].namaDivisi,
                          }
                    );
                    setAtasan(
                      row.atasan.length === 0
                        ? ""
                        : {
                            value: row.atasan[0].idUser,
                            label: row.atasan[0].namaLengkap,
                          }
                    );
                    setRole(
                      row.role.length === 0
                        ? ""
                        : {
                            value: row.role[0].idRole,
                            label: row.role[0].namaRole,
                          }
                    );
                    setResign(
                      row.resign === "Yes"
                        ? { value: "Yes", label: "Ya" }
                        : { value: "No", label: "Tidak" }
                    );
                    setTglResign(row.tglResign);
                    setAlamatKtp(row.alamatKtp);
                    setRtKtp(row.rtKtp);
                    setRwKtp(row.rwKtp);
                    setKelurahanKtp(row.kelurahanKtp);
                    setKecamatanKtp(row.kecamatanKtp);
                    setKotaKtp(row.kotaKtp);
                    setProvinsiKtp(row.provinsiKtp);
                    setAlamatDomisili(row.alamatDomisili);
                    setRtDomisili(row.rtDomisili);
                    setRwDomisili(row.rwDomisili);
                    setKelurahanDomisili(row.kelurahanDomisili);
                    setKecamatanDomisili(row.kecamatanDomisili);
                    setKotaDomisili(row.kotaDomisili);
                    setProvinsiDomisili(row.provinsiDomisili);
                    let menu = [];
                    if (!row.menu || row.menu.length === 0) {
                    } else {
                      row.menu.map((item) => {
                        menu.push({
                          value: item,
                          label: item,
                        });
                      });
                    }
                    setMenu(!row.menu || row.menu.length === 0 ? "" : menu);
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                  >
                    <i
                      style={{ color: "#478964", fontSize: 16 }}
                      className="fas fa-edit"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}

              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <div
                  onClick={() => {
                    Swal.fire({
                      title: `Apakah anda yakin untuk menghapus karyawan dengan nama ${row.namaLengkap}?`,
                      text: "Pastikan yang anda lakukan sudah benar!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ok!",
                      cancelButtonText: "Batal!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: "Loading...!",
                          text: "Proses hapus karyawan sedang dilakukan,tunggu proses hingga selesai!",
                          icon: "warning",
                          allowOutsideClick: false,
                          didOpen: () => {
                            Swal.showLoading();
                          },
                        });
                        deleteKaryawan({ idUser: row.idUser });
                      }
                    });
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                  >
                    <i
                      style={{ color: "#FF0000", fontSize: 16 }}
                      className="fas fa-trash"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },

    {
      name: "No Karyawan",
      width: "200px",
      center: true,
      selector: (row) => row.noKaryawan,
    },
    {
      name: "Nama Lengkap",
      width: "300px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "NIK",
      width: "200px",
      center: true,
      selector: (row) => row.nik,
    },
    {
      name: "Status Karyawan",
      width: "200px",
      center: true,
      selector: (row) => (row.resign === "No" ? "Aktif" : "Resign"),
    },
    {
      name: "Divisi",
      width: "200px",
      center: true,
      selector: (row) =>
        row.divisi.length === 0 ? "-" : row.divisi[0].namaDivisi,
    },
    {
      name: "Jabatan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.jabatan.length === 0 ? "-" : row.jabatan[0].namaJabatan,
    },
    {
      name: "Atasan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.atasan.length === 0 ? "-" : row.atasan[0].namaLengkap,
    },
    {
      name: "Role",
      width: "200px",
      center: true,
      selector: (row) => (row.role.length === 0 ? "-" : row.role[0].namaRole),
    },
  ];

  const addKaryawan = (e) => {
    e.preventDefault();
    setPending(true);
    tambahKaryawan({
      namaLengkap: namaKaryawan,
      namaPanggilan: namaPanggilan,
      email: email,
      nik: nik,
      tempatLahir: tempatLahir,
      tanggalLahir: tanggalLahir,
      alamatKtp: alamatKtp,
      rtKtp: rtKtp,
      rwKtp: rwKtp,
      kelurahanKtp: kelurahanKtp,
      kecamatanKtp: kecamatanKtp,
      kotaKtp: kotaKtp,
      provinsiKtp: provinsiKtp,
      alamatDomisili: alamatDomisili,
      rtDomisili: rtDomisili,
      rwDomisili: rwDomisili,
      kelurahanDomisili: kelurahanDomisili,
      kecamatanDomisili: kecamatanDomisili,
      kotaDomisili: kotaDomisili,
      provinsiDomisili: provinsiDomisili,
      noTelp: noTelp,
      awalBekerja: awalKerja,
      idRole: role.value,
      idAtasan: atasan.value,
      idDivisi: divisi.value,
      idJabatan: jabatan.value,
    }).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setNamaKaryawan("");
        setNamaPanggilan("");
        setEmail("");
        setNik("");
        setTempatLahir("");
        setTanggalLahir("");
        setNoTelp("");
        setAwalKerja("");
        setJabatan("");
        setDivisi("");
        setAtasan("");
        setRole("");
        setAlamatKtp("");
        setRtKtp("");
        setRwKtp("");
        setKelurahanKtp("");
        setKecamatanKtp("");
        setKotaKtp("");
        setProvinsiKtp("");
        setAlamatDomisili("");
        setRtDomisili("");
        setRwDomisili("");
        setKelurahanDomisili("");
        setKecamatanDomisili("");
        setKotaDomisili("");
        setProvinsiDomisili("");
        setModal(false);
        getKaryawan(dispatch, {
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
        });
        Swal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Karyawan"
            : response.data.message,
        });
      }
    });
  };

  const editKaryawan = (e) => {
    e.preventDefault();

    let newMenu = [];

    if (menu != "") {
      menu.map((item) => {
        newMenu.push(item.value);
      });
    }

    setPending(true);
    editDataKaryawan({
      idUser: idUser,
      namaLengkap: namaKaryawan,
      namaPanggilan: namaPanggilan,
      email: email,
      nik: nik,
      tempatLahir: tempatLahir,
      tanggalLahir: tanggalLahir,
      alamatKtp: alamatKtp,
      rtKtp: rtKtp,
      rwKtp: rwKtp,
      kelurahanKtp: kelurahanKtp,
      kecamatanKtp: kecamatanKtp,
      kotaKtp: kotaKtp,
      provinsiKtp: provinsiKtp,
      alamatDomisili: alamatDomisili,
      rtDomisili: rtDomisili,
      rwDomisili: rwDomisili,
      kelurahanDomisili: kelurahanDomisili,
      kecamatanDomisili: kecamatanDomisili,
      kotaDomisili: kotaDomisili,
      provinsiDomisili: provinsiDomisili,
      noTelp: noTelp,
      awalBekerja: awalKerja,
      idRole: role.value,
      idAtasan: atasan.value,
      idDivisi: divisi.value,
      idJabatan: jabatan.value,
      tglResign: tglResign,
      resign: resign.value,
      menu: menu === "" || menu === null ? [] : newMenu,
    }).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setIdUser("");
        setNamaKaryawan("");
        setNamaPanggilan("");
        setEmail("");
        setNik("");
        setTempatLahir("");
        setTanggalLahir("");
        setNoTelp("");
        setAwalKerja("");
        setJabatan("");
        setDivisi("");
        setAtasan("");
        setRole("");
        setAlamatKtp("");
        setRtKtp("");
        setRwKtp("");
        setKelurahanKtp("");
        setKecamatanKtp("");
        setKotaKtp("");
        setProvinsiKtp("");
        setAlamatDomisili("");
        setRtDomisili("");
        setRwDomisili("");
        setKelurahanDomisili("");
        setKecamatanDomisili("");
        setKotaDomisili("");
        setProvinsiDomisili("");
        setTglResign("");
        setResign("");
        setModalEdit(false);
        getKaryawan(dispatch, {
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
        });
        Swal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Karyawan"
            : response.data.message,
        });
      }
    });
  };

  const deleteKaryawan = (data) => {
    setPending(true);
    deleteDataKaryawan({ idUser: data.idUser }).then((response) => {
      if (response.data.status === 200) {
        Swal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        getKaryawan(dispatch, {
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data ? "Gagal hapus Karyawan" : response.data.message,
        });
      }
    });
  };

  const importFile = async (e) => {
    e.preventDefault();

    setPending(true);
    const formData = new FormData();

    excel.forEach((file) => {
      formData.append("excelFile", file);
    });

    await importKaryawan(formData).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setExcel([]);
        setModalImport(false);
        getKaryawan(dispatch, {
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
        });
        Swal.fire({
          title: "Sukses",
          text: response.data.message,
          icon: "success",
          showConfirmButton: true,
          // timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data ? "Gagal import produk" : response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    let tmp =
      karyawan.listKaryawan.data &&
      karyawan.listKaryawan.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListKaryawan(tmp);
    if (karyawan.listKaryawan.length != 0) {
      setPending(false);
    }
  }, [karyawan.listKaryawan]);

  React.useEffect(() => {
    getKaryawan(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    });
  }, [page, totalPerHalaman, searchText]);

  React.useEffect(() => {
    getDivisi(dispatch);
    getJabatan(dispatch);
    getRole(dispatch);
    getKaryawanList(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>Karyawan</div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Karyawan
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/100qOYx8NA1OmF4loD7zrMfHu_K9q1_Hm/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama/No Karyawan/NIK"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listKaryawan}
                  //   pagination
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  //   onChangePage={handlePageChange}
                  progressPending={pending}
                />
                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "bold",
                              textTransform: "none",
                              fontSize: 14,
                            }}
                          >
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={karyawan.listKaryawan.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        initialPage={page}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Import Karyawan */}
      <Modal
        size="md"
        show={modalImport}
        onHide={() => {
          pending ? null : setModalImport(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Import Karyawan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col
                  sm="4"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  {/* <div>Texts</div> */}
                  <label
                    className="label-input-file btn text-white mb-4"
                    htmlFor="import2"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#447DF7",
                      border: 0,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      <i
                        className="fas fa-file-excel"
                        style={{ marginRight: 5 }}
                      ></i>
                      Pilih File
                    </div>

                    <input
                      disabled={pending ? true : false}
                      type="file"
                      id="import2"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".xlsx"
                      multiple
                    />
                  </label>
                </Col>
                <Col
                  style={{
                    alignContent: "center",
                  }}
                >
                  {excel.length === 0 ? (
                    <div>
                      <p style={{ color: "GrayText" }}>File kosong</p>
                    </div>
                  ) : (
                    excel.map((file, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        <p className="mb-1 mr-1">{file.name}</p>

                        <i
                          className="fa fa-times"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: 10,
                          }}
                          onClick={() => (pending ? null : removeExcel(index))}
                        />
                      </div>
                    ))
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                disabled={excel.length === 0 || pending ? true : false}
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={importFile}
              >
                {pending ? "Loading..." : "Submit"}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Tambah Karyawan */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);

          setNamaKaryawan("");
          setNamaPanggilan("");
          setEmail("");
          setNik("");
          setTempatLahir("");
          setTanggalLahir("");
          setNoTelp("");
          setAwalKerja("");
          setJabatan("");
          setDivisi("");
          setAtasan("");
          setRole("");
          setAlamatKtp("");
          setRtKtp("");
          setRwKtp("");
          setKelurahanKtp("");
          setKecamatanKtp("");
          setKotaKtp("");
          setProvinsiKtp("");
          setAlamatDomisili("");
          setRtDomisili("");
          setRwDomisili("");
          setKelurahanDomisili("");
          setKecamatanDomisili("");
          setKotaDomisili("");
          setProvinsiDomisili("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Karyawan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nama Karyawan
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaKaryawan(e.target.value);
                        }}
                        placeholder="Nama Karyawan"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nama Panggilan
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaPanggilan(e.target.value);
                        }}
                        placeholder="Nama Panggilan"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Email
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Email"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        NIK
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNik(e.target.value);
                        }}
                        placeholder="NIK"
                        type="text"
                        maxLength={16}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Tempat Lahir
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTempatLahir(e.target.value);
                        }}
                        placeholder="Tempat Lahir"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Tanggal Lahir
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTanggalLahir(e.target.value);
                        }}
                        placeholder="YYYY-MM-DD"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        No Telp
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNoTelp(e.target.value);
                        }}
                        placeholder="No Telp"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Awal Bekerja
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAwalKerja(e.target.value);
                        }}
                        placeholder="YYYY-MM-DD"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Jabatan
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Jabatan"
                        value={jabatan}
                        onChange={(value) => {
                          setJabatan(value);
                        }}
                        options={listJabatan}
                        placeholder="Pilih Jabatan"
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Divisi
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Jabatan"
                        value={divisi}
                        onChange={(value) => {
                          setDivisi(value);
                        }}
                        options={listDivisi}
                        placeholder="Pilih Divisi"
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Atasan
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Atasan"
                        value={atasan}
                        onChange={(value) => {
                          setAtasan(value);
                        }}
                        options={listAtasan}
                        placeholder="Pilih Atasan"
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Role
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Role"
                        value={role}
                        onChange={(value) => {
                          setRole(value);
                        }}
                        options={listRole}
                        placeholder="Pilih Role"
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <b>Detail Alamat</b>
              </div>
              <hr></hr>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Alamat KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAlamatKtp(e.target.value);
                        }}
                        placeholder="Alamat KTP"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RT KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRtKtp(e.target.value);
                        }}
                        placeholder="RT KTP"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RW KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRwKtp(e.target.value);
                        }}
                        placeholder="RW KTP"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kelurahan KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKelurahanKtp(e.target.value);
                        }}
                        placeholder="Kelurahan KTP"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kecamatan KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKecamatanKtp(e.target.value);
                        }}
                        placeholder="Kecamatan KTP"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kota KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKotaKtp(e.target.value);
                        }}
                        placeholder="Kota KTP"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Provinsi KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setProvinsiKtp(e.target.value);
                        }}
                        placeholder="Provinsi KTP"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Alamat Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAlamatDomisili(e.target.value);
                        }}
                        placeholder="Alamat Domisili"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RT Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRtDomisili(e.target.value);
                        }}
                        placeholder="RT Domisili"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RW Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRwDomisili(e.target.value);
                        }}
                        placeholder="RW Domisili"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kelurahan Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKelurahanDomisili(e.target.value);
                        }}
                        placeholder="Kelurahan Domisili"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kecamatan Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKecamatanDomisili(e.target.value);
                        }}
                        placeholder="Kecamatan Domisili"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kota Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKotaDomisili(e.target.value);
                        }}
                        placeholder="Kota Domisili"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Provinsi Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setProvinsiDomisili(e.target.value);
                        }}
                        placeholder="Provinsi Domisili"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={addKaryawan}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Detail Karyawan */}
      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => {
          setModalDetail(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Karyawan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Lengkap
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailKaryawan && detailKaryawan.namaLengkap}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Panggilan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailKaryawan && detailKaryawan.namaPanggilan === ""
                    ? "-"
                    : detailKaryawan && detailKaryawan.namaPanggilan}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  NIK
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailKaryawan && detailKaryawan.nik}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  No Telpn
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailKaryawan && detailKaryawan.noTelp === ""
                    ? "-"
                    : detailKaryawan && detailKaryawan.noTelp}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Divisi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailKaryawan && detailKaryawan.divisi.length === 0
                    ? "-"
                    : detailKaryawan && detailKaryawan.divisi[0].namaDivisi}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Jabatan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailKaryawan && detailKaryawan.jabatan.length === 0
                    ? "-"
                    : detailKaryawan && detailKaryawan.jabatan[0].namaJabatan}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Atasan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailKaryawan && detailKaryawan.atasan.length === 0
                    ? "-"
                    : detailKaryawan && detailKaryawan.atasan[0].namaLengkap}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Akses Menu
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailKaryawan && !detailKaryawan.menu
                    ? "-"
                    : detailKaryawan && detailKaryawan.menu.length === 0
                    ? "-"
                    : detailKaryawan &&
                      detailKaryawan.menu.map((val) => {
                        return <li>{val}</li>;
                      })}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      {/* Modal Update Data */}
      <Modal
        size="lg"
        show={modalEdit}
        onHide={() => {
          setModalEdit(false);

          setNamaKaryawan("");
          setNamaPanggilan("");
          setEmail("");
          setNik("");
          setTempatLahir("");
          setTanggalLahir("");
          setNoTelp("");
          setAwalKerja("");
          setJabatan("");
          setDivisi("");
          setAtasan("");
          setRole("");
          setAlamatKtp("");
          setRtKtp("");
          setRwKtp("");
          setKelurahanKtp("");
          setKecamatanKtp("");
          setKotaKtp("");
          setProvinsiKtp("");
          setAlamatDomisili("");
          setRtDomisili("");
          setRwDomisili("");
          setKelurahanDomisili("");
          setKecamatanDomisili("");
          setKotaDomisili("");
          setProvinsiDomisili("");
          setMenu("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Edit Karyawan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nama Karyawan
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaKaryawan(e.target.value);
                        }}
                        placeholder="Nama Karyawan"
                        type="text"
                        value={namaKaryawan}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nama Panggilan
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaPanggilan(e.target.value);
                        }}
                        placeholder="Nama Panggilan"
                        type="text"
                        value={namaPanggilan}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Email
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Email"
                        type="text"
                        value={email}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        NIK
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNik(e.target.value);
                        }}
                        placeholder="NIK"
                        type="text"
                        maxLength={16}
                        value={nik}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Tempat Lahir
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTempatLahir(e.target.value);
                        }}
                        placeholder="Tempat Lahir"
                        type="text"
                        value={tempatLahir}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Tanggal Lahir
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTanggalLahir(e.target.value);
                        }}
                        placeholder="YYYY-MM-DD"
                        type="text"
                        value={tanggalLahir}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        No Telp
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNoTelp(e.target.value);
                        }}
                        placeholder="No Telp"
                        type="text"
                        value={noTelp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Awal Bekerja
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAwalKerja(e.target.value);
                        }}
                        placeholder="YYYY-MM-DD"
                        type="text"
                        value={awalKerja}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Jabatan
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Jabatan"
                        value={jabatan}
                        onChange={(value) => {
                          setJabatan(value);
                        }}
                        options={listJabatan}
                        placeholder="Pilih Jabatan"
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Divisi
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Jabatan"
                        value={divisi}
                        onChange={(value) => {
                          setDivisi(value);
                        }}
                        options={listDivisi}
                        placeholder="Pilih Divisi"
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Atasan
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Atasan"
                        value={atasan}
                        onChange={(value) => {
                          setAtasan(value);
                        }}
                        options={listAtasan}
                        placeholder="Pilih Atasan"
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Role
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Role"
                        value={role}
                        onChange={(value) => {
                          setRole(value);
                        }}
                        options={listRole}
                        placeholder="Pilih Role"
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Status Resign
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Status Resign"
                        value={resign}
                        onChange={(value) => {
                          setResign(value);
                        }}
                        options={[
                          { label: "Ya", value: "Yes" },
                          { label: "Tidak", value: "No" },
                        ]}
                        placeholder="Pilih Status Resign"
                      />
                    </Form.Group>
                  </Form>
                </Col>
                {resign.value === "Yes" ? (
                  <Col md="6">
                    <Form action="#" method="#">
                      <Form.Group>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Tanggal Resign
                        </label>
                        <Form.Control
                          onChange={(e) => {
                            setTglResign(e.target.value);
                          }}
                          placeholder="YYYY-MM-DD"
                          type="text"
                          value={tglResign}
                        ></Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                ) : null}
              </Row>
              <div style={{ marginTop: 20 }}>
                <b>Detail Alamat</b>
              </div>
              <hr></hr>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Alamat KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAlamatKtp(e.target.value);
                        }}
                        placeholder="Alamat KTP"
                        type="text"
                        value={alamatKtp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RT KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRtKtp(e.target.value);
                        }}
                        placeholder="RT KTP"
                        type="text"
                        value={rtKtp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RW KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRwKtp(e.target.value);
                        }}
                        placeholder="RW KTP"
                        type="text"
                        value={rwKtp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kelurahan KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKelurahanKtp(e.target.value);
                        }}
                        placeholder="Kelurahan KTP"
                        type="text"
                        value={kelurahanKtp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kecamatan KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKecamatanKtp(e.target.value);
                        }}
                        placeholder="Kecamatan KTP"
                        type="text"
                        value={kecamatanKtp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kota KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKotaKtp(e.target.value);
                        }}
                        placeholder="Kota KTP"
                        type="text"
                        value={kotaKtp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Provinsi KTP
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setProvinsiKtp(e.target.value);
                        }}
                        placeholder="Provinsi KTP"
                        type="text"
                        value={provinsiKtp}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Alamat Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAlamatDomisili(e.target.value);
                        }}
                        placeholder="Alamat Domisili"
                        type="text"
                        value={alamatDomisili}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RT Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRtDomisili(e.target.value);
                        }}
                        placeholder="RT Domisili"
                        type="text"
                        value={rtDomisili}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        RW Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setRwDomisili(e.target.value);
                        }}
                        placeholder="RW Domisili"
                        type="text"
                        value={rwDomisili}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kelurahan Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKelurahanDomisili(e.target.value);
                        }}
                        placeholder="Kelurahan Domisili"
                        type="text"
                        value={kelurahanDomisili}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kecamatan Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKecamatanDomisili(e.target.value);
                        }}
                        placeholder="Kecamatan Domisili"
                        type="text"
                        value={kecamatanDomisili}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Kota Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setKotaDomisili(e.target.value);
                        }}
                        placeholder="Kota Domisili"
                        type="text"
                        value={kotaDomisili}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Provinsi Domisili
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setProvinsiDomisili(e.target.value);
                        }}
                        placeholder="Provinsi Domisili"
                        type="text"
                        value={provinsiDomisili}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <b>Edit Akses Menu</b>
              </div>
              <hr></hr>
              <Row>
                <Col md="6">
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Akses Menu yang diijinkan
                      </label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Pilih Menu"
                        name="multipleSelect"
                        closeMenuOnSelect={false}
                        isMulti
                        value={menu}
                        onChange={(value) => {
                          if (value === null) {
                            setMenu("");
                          } else {
                            setMenu(value);
                          }
                        }}
                        options={[
                          { value: "Dashboard", label: "Dashboard" },
                          { value: "Karyawan", label: "Karyawan" },
                          { value: "Produk", label: "Produk" },
                          { value: "Produk Card", label: "Produk Card" },
                          { value: "Sales Order", label: "Sales Order " },
                          { value: "Pesan Langsung", label: "Pesan Langsung" },
                          { value: "Invoice", label: "Invoice" },
                          { value: "Surat Jalan", label: "Surat Jalan" },
                          { value: "Setting", label: "Setting" },
                          { value: "Purchase Order", label: "Purchase Order" },
                        ]}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={editKaryawan}
              >
                Edit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default Karyawan;
