import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getProduk(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/produkWeb/getProduk?page=${data.page}&limit=${data.limit}&cari=${data.cari}&kategori=utama`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLPRODUK", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getProdukUpgrade(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/produkWeb/getProduk?page=${data.page}&limit=${data.limit}&cari=${data.cari}&kategori=upgrade`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLPRODUKUPGRADE", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahProduk(data) {
  try {
    const response = await baseAxios.post("/produkWeb/addProduk", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function importProduk(data) {
  try {
    const response = await baseAxios.post(
      "/produkWeb/importExcelProduk",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function editProduk(data) {
  try {
    const response = await baseAxios.patch("/produkWeb/editProduk", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteProduk(data) {
  try {
    const response = await baseAxios.patch("/produkWeb/deleteProduk", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getProdukPo(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/produkWeb/getProdukHarusPo?page=${data.page}&limit=${data.limit}&cari=${data.cari}&kategori=utama`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLPRODUKPO", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getProdukUpgradePo(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/produkWeb/getProdukHarusPo?page=${data.page}&limit=${data.limit}&cari=${data.cari}&kategori=upgrade`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLPRODUKUPGRADEPO", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getTotalProdukPo(dispatch, data, history) {
  try {
    const response = await baseAxios(`/produkWeb/getTotalProdukHarusPo`, {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({ type: "SET_TOTALPRODUKPO", data: response.data.total });
  } catch (err) {
    return err.response;
  }
}

export async function tambahPo(data) {
  try {
    const response = await baseAxios.post("/poWeb/addPo", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportAllProduk(dispatch) {
  try {
    const response = await baseAxios("/produkWeb/exportExcelProduk", {
      headers: {
        token: localStorage.getItem("token"),
      },
      responseType: "blob",
    });

    return response;
  } catch (err) {
    return err.response;
  }
}
