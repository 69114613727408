import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getKaryawan(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/userWeb/getUser?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLKARYAWAN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahKaryawan(data) {
  try {
    const response = await baseAxios.post("/userWeb/addUser", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function importKaryawan(data) {
  try {
    const response = await baseAxios.post(
      "/userWeb/importExcelKaryawan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getKaryawanList(dispatch, data, history) {
  try {
    const response = await baseAxios("/userWeb/getUserDropDown", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLKARYAWANDROPDOWN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function editDataKaryawan(data) {
  try {
    const response = await baseAxios.patch("/userWeb/updateUser", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteDataKaryawan(data) {
  try {
    const response = await baseAxios.patch("/userWeb/deleteUser", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
