import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getOrder,
  getInvoice,
  createInvoice,
  updateInvoice,
  cetakInv,
} from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";
import NotificationAlert from "react-notification-alert";

function InvoiceOrder() {
  const dispatch = useDispatch();
  const notificationAlertRef = React.useRef(null);
  const order = useSelector((state) => state.orderReducer);
  const invoice = useSelector((state) => state.invoiceReducer);
  const [listOrder, setListOrder] = React.useState([]);
  const [listInvoice, setListInvoice] = React.useState([]);

  const [modal, setModal] = React.useState(false);
  const [detailInvoice, setDetailInvoice] = React.useState(null);
  const [modalOrder, setModalOrder] = React.useState(false);
  const [detailOrder, setDetailOrder] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [showListInvoice, setShowListInvoice] = React.useState(false);

  const [totalPerHalamanInv, setTotalPerHalamanInv] = React.useState(10);
  const [pageInv, setPageInv] = React.useState(0);
  const [searchTextInv, setSearchTextInv] = React.useState("");

  const [modalAddInv, setModalAddInv] = React.useState(false);
  const [nominalAdmin, setNominalAdmin] = React.useState(0);
  const [nominalPeti, setNominalPeti] = React.useState(0);
  const [tmpNominalPeti, setTmpNominalPeti] = React.useState(0);
  const [nominalOngkir, setNominalOngkir] = React.useState(0);
  const [tmpNominalOngkir, setTmpNominalOngkir] = React.useState(0);
  const [nominalDiskon, setNominalDiskon] = React.useState(0);
  const [nominalDp, setNominalDp] = React.useState(0);
  const [nominalSisaBayar, setNominalSisaBayar] = React.useState(0);
  const [nominalLunas, setNominalLunas] = React.useState(0);
  const [jasaOnline, setJasaOnline] = React.useState(0);
  const [tipePembayaran, setTipePembayaran] = React.useState("");
  const [idInvoice, setIdInvoice] = React.useState("");
  const [dokumen, setDokumen] = React.useState([]);
  const [editedNominalOngkir, setEditedNominalOngkir] = React.useState(false);

  const [modalEditInv, setModalEditInv] = React.useState(false);
  const [nominalAdminValidasi, setNominalAdminValidasi] = React.useState(0);
  const [nominalPetiValidasi, setNominalPetiValidasi] = React.useState(0);
  const [nominalOngkirValidasi, setNominalOngkirValidasi] = React.useState(0);
  const [nominalDiskonValidasi, setNominalDiskonValidasi] = React.useState(0);
  const [nominalDpValidasi, setNominalDpValidasi] = React.useState(0);
  const [nominalLunasValidasi, setNominalLunasValidasi] = React.useState(0);
  const [tipePembayaranValidasi, setTipePembayaranValidasi] =
    React.useState("");
  const [dokumenValidasi, setDokumenValidasi] = React.useState(false);

  const [newInvoice, setNewInvoice] = React.useState(false);

  function handleFileChange(e) {
    // const selectedFiles = Array.from(e.target.files);
    setDokumen([...dokumen, ...Array.from(e.target.files)]);
    setDokumenValidasi(false);
  }

  const removeDokumen = (index) => {
    setDokumen(dokumen.filter((_, i) => i !== index));
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const handleSearchInv = (e) => {
    setSearchTextInv(e.target.value);
  };
  const handleClearSearchInv = () => {
    setSearchTextInv("");
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  const handlePageSizeChangeInv = (selectedOption) => {
    setTotalPerHalamanInv(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + order.listOrderProduk.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  // setDetailInvoice(row);
                  if (row.statusSo === "Cancel") {
                    null;
                  } else {
                    setShowListInvoice(true);
                    localStorage.setItem("idSo", row.idSo);
                    localStorage.setItem("showInv", "true");
                    localStorage.setItem("totalPembelian", row.totalPembelian);
                    localStorage.setItem("tipeOrder", row.tipeSo);
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                  cursor: row.statusSo === "Cancel" ? "unset" : "pointer",
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-48903503">Detail Invoice</Tooltip>
                  }
                >
                  <i
                    style={{
                      color: row.statusSo === "Cancel" ? "grey" : "#447DF7",
                      fontSize: 16,
                    }}
                    className="fas fa-file-invoice-dollar"
                  ></i>
                </OverlayTrigger>
              </div>
              <div
                onClick={() => {
                  setDetailOrder(row);
                  setModalOrder(true);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-48903503">Detail Order</Tooltip>
                  }
                >
                  <i
                    style={{ color: "#FFA32F", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                >
                  <i
                    style={{ color: "#478964", fontSize: 16 }}
                    className="fas fa-edit"
                  ></i>
                </OverlayTrigger>
              </div>
              <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "Status Order",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusSo === "Ajukan" ? (
          <div
            style={{
              backgroundColor: "rgba(255,163,47,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(255,163,47)",
            }}
          >
            <b style={{ color: "rgb(255,163,47)" }}>Diorder</b>
          </div>
        ) : row.statusSo === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
          </div>
        ) : row.statusSo === "Tolak" ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
          </div>
        ) : row.statusSo === "Kirim" ? (
          <div
            style={{
              backgroundColor: "rgba(68,125,247,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(68,125,247)",
            }}
          >
            <b style={{ color: "rgb(68,125,247)" }}>Dikirim</b>
          </div>
        ) : row.statusSo === "Belum" ? (
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(0,0,0)",
            }}
          >
            <b style={{ color: "rgb(0,0,0)" }}>Belum Kirim</b>
          </div>
        ) : row.statusSo === "Pending" ? (
          <div
            style={{
              backgroundColor: "rgba(247, 0, 239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(247, 0, 239)",
            }}
          >
            <b style={{ color: "rgb(247, 0, 239)" }}>Pending</b>
          </div>
        ) : row.statusSo === "Diambil" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Diambil</b>
          </div>
        ) : row.statusSo === "Cancel" ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Cancel</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
          </div>
        ),
    },
    {
      name: "Status Pembayaran",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusPembayaran === "" || !row.statusPembayaran ? (
          <span style={{ color: "rgb(251,59,70)" }}>
            <b>-</b>
          </span>
        ) : row.statusPembayaran === "Pending" ? (
          <div
            style={{
              backgroundColor: "rgba(255,163,47,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(255,163,47)",
            }}
          >
            <b style={{ color: "rgb(255,163,47)" }}>Pending</b>
          </div>
        ) : row.statusPembayaran === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(68,125,247,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(68,125,247)",
            }}
          >
            <b style={{ color: "rgb(68,125,247)" }}>Proses</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Lunas</b>
          </div>
        ),
    },
    {
      name: "Tipe Order",
      width: "200px",
      center: true,
      selector: (row) => row.tipeSo,
    },
    {
      name: "Kode Order",
      width: "200px",
      center: true,
      selector: (row) => row.idSo,
    },
    {
      name: "Nama Instansi",
      width: "250px",
      selector: (row) => row.customerInstansi,
    },
    {
      name: "Nama Customer",
      width: "200px",
      selector: (row) => row.namaCustomer,
    },
    {
      name: "Order",
      width: "auto",
      selector: (row) => {
        return (
          <>
            <table cellPadding="4" border="1">
              <thead>
                <tr>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Kategori</th>
                  <th>Sumber</th>
                  <th>Qty</th>
                  <th>Selesai</th>
                </tr>
              </thead>
              <tbody>
                {row.orders.map((val) => {
                  return (
                    <>
                      <tr
                        style={{
                          backgroundColor:
                            val.qty != val.qtySelesai
                              ? "rgba(255, 3, 3,0.3)"
                              : null,
                        }}
                      >
                        <td>{val.produk.kodeProduk}</td>
                        <td>{val.produk.namaProduk}</td>
                        <td>
                          {val.produk.kategoriProduk === "upgrade"
                            ? "UPGRADE"
                            : "UTAMA"}
                        </td>
                        <td>{val.sumberProduk === "PL" ? "PL" : "SG"}</td>
                        <td>{val.qty} item</td>
                        <td style={{ textAlign: "center" }}>
                          {val.qtySelesai} item
                        </td>
                      </tr>
                    </>
                  );

                  // <li style={{ marginTop: 5 }}>
                  //   <b>{val.produk.kodeProduk}</b> - {val.produk.namaProduk}
                  //   {val.produk.kategoriProduk === "upgrade" ? " - UPGRADE" : null}
                  //   {val.sumberProduk === "PL" ? (
                  //     <b>
                  //       {" "}
                  //       <i style={{ color: "blue" }}> - PL</i>
                  //     </b>
                  //   ) : null}{" "}
                  //   - <b>{val.qty} item</b>
                  // </li>
                })}
              </tbody>
            </table>
          </>
        );
      },
    },
    {
      name: "Total Harga",
      width: "200px",
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.totalPembelian);
        return formattedValue;
      },
    },
    {
      name: "Tanggal Order",
      width: "200px",
      center: true,
      selector: (row) =>
        moments.tz(new Date(row.tglOrder), "Asia/Jakarta").format("DD-MM-YYYY"),
    },
  ];

  const cetakInvPdf = (data) => {
    cetakInv({ idSo: data.idSo, idInv: data.idInvoice }).then((response) => {
      if (response.status === 200) {
        const byteCharacters = atob(response.data.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        const newTab = window.open(blobUrl, "_blank");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Produk"
            : response.data.message,
        });
      }
    });
  };
  const columnsInv = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + invoice.listInvoice.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setModal(true);
                  setDetailInvoice(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              <div
                onClick={() => {
                  cetakInvPdf(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Cetak PDF</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-file-pdf"
                  ></i>
                </OverlayTrigger>
              </div>
              {localStorage.getItem("tipeOrder") === "Online" ? (
                <div
                  cursor={
                    row.order.statusPembayaran === "Lunas"
                      ? "not-allowed"
                      : "pointer"
                  }
                  onClick={() => {
                    if (row.order.statusPembayaran === "Lunas") {
                      return null;
                    } else {
                      setModalEditInv(true);
                      setNominalAdmin(row.nominalAdmin);
                      setNominalPeti(row.nominalPeti);
                      setNominalOngkir(row.nominalOngkir);
                      setNominalDiskon(row.nominalDiskon);
                      setNominalSisaBayar(row.nominalSisaBayar);
                      setTipePembayaran({ value: "LN", label: "Lunas" });
                      setIdInvoice(row.idInvoice);
                    }
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                  >
                    <i
                      style={{
                        color:
                          row.order.statusPembayaran === "Lunas"
                            ? "grey"
                            : "#478964",
                        fontSize: 16,
                      }}
                      className="fas fa-edit"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}

              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "No Invoice",
      width: "200px",
      center: true,
      selector: (row) => row.idInvoice,
    },
    {
      name: "Tipe Pembayaran",
      width: "200px",
      center: true,
      selector: (row) => (row.tipePembayaran === "DP" ? "DP" : "Lunas"),
    },
    {
      name: "Status Pembayaran",
      width: "200px",
      center: true,
      selector: (row) =>
        row.order.statusPembayaran === "Pending" ? (
          <span style={{ color: "rgb(255,163,47)" }}>
            <b>Pending</b>
          </span>
        ) : row.order.statusPembayaran === "Proses" ? (
          <span style={{ color: "rgb(68,125,247)" }}>
            <b>Proses</b>
          </span>
        ) : (
          <span style={{ color: "rgb(36,204,23)" }}>
            <b>Lunas</b>
          </span>
        ),
    },
    {
      name: "Nama Admin",
      width: "200px",
      center: true,
      selector: (row) => row.admin.namaLengkap,
    },
    {
      name: "Total Pembelian",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.totalPembelian);
      },
    },
    {
      name: "Nominal Admin",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAdmin);
      },
    },
    {
      name: "Nominal Peti",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalPeti);
      },
    },
    {
      name: "Nominal Ongkir",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalOngkir);
      },
    },
    {
      name: "Nominal Diskon",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalDiskon);
      },
    },
    {
      name: "Nominal Bayar",
      width: "200px",
      center: true,
      selector: (row, index) => {
        return index === 0
          ? new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 0,
            }).format(
              row.totalPembelian +
                row.nominalAdmin +
                row.nominalPeti +
                row.nominalOngkir -
                row.nominalDiskon
            )
          : row.tipePembayaran === "DP"
          ? new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 0,
            }).format(row.nominalDp)
          : new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 0,
            }).format(row.nominalLunas);
        // return new Intl.NumberFormat("id-ID", {
        //   style: "currency",
        //   currency: "IDR",
        //   minimumFractionDigits: 0,
        // }).format(
        //   row.totalPembelian +
        //     row.nominalAdmin +
        //     row.nominalPeti +
        //     row.nominalOngkir -
        //     row.nominalDiskon
        // );
      },
    },
    {
      name: "Nominal DP",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalDp);
      },
    },
    {
      name: "Nominal Lunas",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalLunas);
      },
    },
    {
      name: "Nominal Sisa Bayar",
      width: "200px",
      center: true,
      selector: (row) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalSisaBayar);
      },
    },
    {
      name: "Tanggal Invoice",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.tglInvoice), "Asia/Jakarta")
          .format("DD-MM-YYYY"),
    },
    // {
    //   name: "Status Order",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     row.statusSo === "Ajukan" ? (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(255,163,47,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(255,163,47)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(255,163,47)" }}>Diorder</b>
    //       </div>
    //     ) : row.statusSo === "Proses" ? (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(36,204,239,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(36,204,239)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
    //       </div>
    //     ) : row.statusSo === "Tolak" ? (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(251,59,70,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(251,59,70)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
    //       </div>
    //     ) : row.statusSo === "Kirim" ? (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(68,125,247,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(68,125,247)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(68,125,247)" }}>Dikirim</b>
    //       </div>
    //     ) : (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(36,204,23,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(36,204,23)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
    //       </div>
    //     ),
    // },
    // {
    //   name: "Status Lunas",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     row.statusLunas === false ? (
    //       <span style={{ color: "rgb(251,59,70)" }}>
    //         <b>Belum Lunas</b>
    //       </span>
    //     ) : (
    //       <span style={{ color: "rgb(36,204,23)" }}>
    //         <b>Lunas</b>
    //       </span>
    //     ),
    // },
    // {
    //   name: "Kode Order",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.idSo,
    // },
    // {
    //   name: "Nama Instansi",
    //   width: "250px",
    //   selector: (row) => row.customerInstansi,
    // },
    // {
    //   name: "Nama Customer",
    //   width: "200px",
    //   selector: (row) => row.namaCustomer,
    // },
    // {
    //   name: "Jenis Produk",
    //   width: "250px",
    //   selector: (row) =>
    //     row.orders.map((val) => {
    //       return (
    //         <li>
    //           {val.produk.kodeProduk}-{val.produk.namaProduk}
    //         </li>
    //       );
    //     }),
    // },
    // {
    //   name: "Total Harga",
    //   width: "200px",
    //   selector: (row) => {
    //     const formattedValue = new Intl.NumberFormat("id-ID", {
    //       style: "currency",
    //       currency: "IDR",
    //       minimumFractionDigits: 0,
    //     }).format(row.totalPembelian);
    //     return formattedValue;
    //   },
    // },
    // {
    //   name: "Tanggal Order",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments.tz(new Date(row.tglOrder), "Asia/Jakarta").format("DD-MM-YYYY"),
    // },
    // {
    //   name: "Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.jumlahStock,
    // },
    // {
    //   name: "Minimal Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.minimalStock,
    // },
    // {
    //   name: "Tanggal Data Dibuat",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.createdAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
    // {
    //   name: "Tanggal Update Data",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.updatedAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
  ];

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      order.listOrderProduk.data &&
      order.listOrderProduk.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListOrder(tmp);
    if (order.listOrderProduk.length != 0) {
      setPending(false);
    }
  }, [order.listOrderProduk]);

  React.useEffect(() => {
    let tmp =
      invoice.listInvoice.data &&
      invoice.listInvoice.data.map((val, index) => {
        return {
          ...val,
        };
      });

    setListInvoice(tmp);
    if (invoice.listInvoice.length != 0) {
      setPending(false);
    }
  }, [invoice.listInvoice]);

  React.useEffect(() => {
    if (showListInvoice === false) {
      getOrder(dispatch, {
        cari: searchText,
        page: page + 1,
        limit: totalPerHalaman,
      });
    }
  }, [showListInvoice, page, totalPerHalaman, searchText]);

  React.useEffect(() => {
    if (showListInvoice === true) {
      getInvoice(dispatch, {
        cari: searchTextInv,
        page: pageInv + 1,
        limit: totalPerHalamanInv,
        idSo: localStorage.getItem("idSo"),
      });
    }
  }, [showListInvoice, pageInv, totalPerHalamanInv, searchTextInv]);

  React.useEffect(() => {
    let statusShowListInv = localStorage.getItem("showInv");
    if (statusShowListInv === null || statusShowListInv === "false") {
      setShowListInvoice(false);
    } else {
      setShowListInvoice(true);
    }
  }, [showListInvoice]);
  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const buatInvoice = (e) => {
    e.preventDefault();
    if (dokumen.length === 0) {
      setDokumenValidasi(true);
    }

    if (tipePembayaran === "") {
      setTipePembayaranValidasi(true);
    }

    if (nominalAdmin === NaN || nominalAdmin === undefined) {
      setNominalAdminValidasi(true);
    }

    if (nominalPeti === NaN || nominalPeti === undefined) {
      setNominalPetiValidasi(true);
    }

    if (nominalOngkir === NaN || nominalOngkir === undefined) {
      setNominalOngkirValidasi(true);
    }

    if (nominalDiskon === NaN || nominalDiskon === undefined) {
      setNominalDiskonValidasi(true);
    }

    // if (
    //   tipePembayaran.value === "DP" &&
    //   (parseInt(nominalDp) === 0 || nominalDp === undefined)
    // ) {
    //   setNominalDpValidasi(true);
    // }

    if (
      tipePembayaran.value === "LN" &&
      (parseInt(nominalLunas) === 0 ||
        nominalLunas === undefined ||
        nominalLunas === NaN)
    ) {
      setNominalLunasValidasi(true);
    }

    if (
      dokumen.length === 0 ||
      tipePembayaran === "" ||
      nominalAdmin === undefined ||
      nominalAdmin === NaN ||
      nominalPeti === undefined ||
      nominalPeti === NaN ||
      nominalOngkir === undefined ||
      nominalOngkir === NaN ||
      nominalDiskon === undefined ||
      nominalDiskon === NaN ||
      // (tipePembayaran.value === "DP" &&
      //   (parseInt(nominalDp) === 0 || nominalDp === undefined)) ||
      (tipePembayaran.value === "LN" &&
        (parseInt(nominalLunas) === 0 ||
          nominalLunas === undefined ||
          nominalLunas === NaN))
    ) {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      // notify({
      //   code: 0,
      //   message: "Siap kirim",
      // });
      Swal.fire({
        title: "Apakah anda yakin untuk manambahkan invoice?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Invoice sedang dibuat,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          let formData = new FormData();
          formData.append("idSo", localStorage.getItem("idSo"));
          formData.append("tipePembayaran", tipePembayaran.value);
          formData.append(
            "totalPembelian",
            parseInt(localStorage.getItem("totalPembelian"))
          );
          formData.append("nominalAdmin", parseInt(nominalAdmin));
          formData.append("nominalPeti", parseInt(nominalPeti));
          formData.append("nominalOngkir", parseInt(nominalOngkir));
          formData.append("nominalDiskon", parseInt(nominalDiskon));
          formData.append(
            "nominalDp",
            tipePembayaran.value === "LN" ? 0 : parseInt(nominalDp)
          );
          formData.append(
            "nominalLunas",
            tipePembayaran.value === "LN" ? parseInt(nominalLunas) : 0
          );
          formData.append("tipeSo", localStorage.getItem("tipeOrder"));
          formData.append(
            "tglInvoice",
            moments.tz(new Date(), "Asia/Jakarta").format("YYYY-MM-DD")
          );
          dokumen.map((val, index) => {
            formData.append("dokumen", val);
          });

          createInvoice(formData).then((response) => {
            if (response.data.status === 200) {
              setPending(false);
              setNominalAdmin(0);
              setNominalPeti(0);
              setTmpNominalPeti(0);
              setNominalOngkir(0);
              setTmpNominalOngkir(0);
              setNominalDiskon(0);
              setNominalDp(0);
              setNominalLunas(0);
              setDokumen([]);
              setTipePembayaran("");
              setModalAddInv(false);
              setNominalSisaBayar(0);
              setEditedNominalOngkir(false);

              getInvoice(dispatch, {
                cari: searchTextInv,
                page: pageInv + 1,
                limit: totalPerHalamanInv,
                idSo: localStorage.getItem("idSo"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal menambahkan Produk"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  const editInvoice = (e) => {
    e.preventDefault();
    if (dokumen.length === 0) {
      setDokumenValidasi(true);
    }

    if (dokumen.length === 0) {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk mengedit invoice?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Invoice sedang diedit,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          let formData = new FormData();
          formData.append("idSo", localStorage.getItem("idSo"));
          formData.append("idInvoice", idInvoice);
          formData.append("nominalAdmin", parseInt(nominalAdmin));
          formData.append("nominalPeti", parseInt(nominalPeti));
          formData.append("nominalOngkir", parseInt(nominalOngkir));
          formData.append("nominalDiskon", parseInt(nominalDiskon));
          formData.append("nominalDp", parseInt(nominalDp));
          formData.append("nominalLunas", parseInt(nominalLunas));
          formData.append("jasaOnline", parseInt(jasaOnline));
          formData.append("nominalSisaBayar", parseInt(nominalSisaBayar));

          // formData.append(
          //   "tglInvoice",
          //   moments.tz(new Date(), "Asia/Jakarta").format("YYYY-MM-DD")
          // );
          dokumen.map((val, index) => {
            formData.append("dokumen", val);
          });

          updateInvoice(formData).then((response) => {
            if (response.data.status === 200) {
              setPending(false);
              setNominalAdmin(0);
              setNominalPeti(0);
              setTmpNominalPeti(0);
              setNominalOngkir(0);
              setTmpNominalOngkir(0);
              setNominalDiskon(0);
              setNominalDp(0);
              setNominalLunas(0);
              setDokumen([]);
              setTipePembayaran("");
              setModalAddInv(false);
              setNominalSisaBayar(0);
              setEditedNominalOngkir(false);
              setJasaOnline(0);
              setIdInvoice("");
              setModalEditInv(false);

              getInvoice(dispatch, {
                cari: searchTextInv,
                page: pageInv + 1,
                limit: totalPerHalamanInv,
                idSo: localStorage.getItem("idSo"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal menambahkan Produk"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {showListInvoice ? (
        <>
          <Container fluid>
            <Row
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Col>
                <div style={{ fontSize: 25, fontWeight: "bold" }}>
                  List Invoice-{localStorage.getItem("idSo")}-
                  {localStorage.getItem("tipeOrder")}
                </div>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button
                  style={{
                    marginLeft: 20,
                    marginBottom: 0,
                    backgroundColor: "rgba(255,1,0,1)",
                    borderColor: "rgba(255,1,0,1)",
                  }}
                  onClick={() => {
                    localStorage.removeItem("idSo");
                    localStorage.removeItem("showInv");
                    localStorage.removeItem("totalPembelian");
                    localStorage.removeItem("tipeOrder");
                    setShowListInvoice(false);
                    setListInvoice([]);
                  }}
                >
                  <i className="fas fa-times" style={{ marginRight: 10 }}></i>
                  Close Invoice
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    const newInv = () => {
                      setModalAddInv(true);
                      setNewInvoice(true);
                    };
                    const oldInv = () => {
                      let lastArr = listInvoice.length - 1;
                      setModalAddInv(true);
                      setNewInvoice(false);
                      setNominalAdmin(
                        parseInt(listInvoice[lastArr].nominalAdmin)
                      );
                      setNominalPeti(
                        parseInt(listInvoice[lastArr].nominalPeti)
                      );
                      setTmpNominalPeti(
                        parseInt(listInvoice[lastArr].nominalPeti)
                      );
                      setNominalOngkir(
                        parseInt(listInvoice[lastArr].nominalOngkir)
                      );
                      setTmpNominalOngkir(
                        parseInt(listInvoice[lastArr].nominalOngkir)
                      );
                      setNominalDiskon(
                        parseInt(listInvoice[lastArr].nominalDiskon)
                      );
                      setNominalSisaBayar(
                        parseInt(listInvoice[lastArr].nominalSisaBayar)
                      );
                      setNominalLunas(
                        parseInt(listInvoice[lastArr].nominalSisaBayar)
                      );
                    };
                    listInvoice.length === 0
                      ? newInv()
                      : listInvoice[0].order.statusLunas
                      ? null
                      : oldInv();
                  }}
                  disabled={
                    listInvoice && listInvoice.length === 0
                      ? false
                      : listInvoice && listInvoice[0].order.statusLunas
                      ? true
                      : false
                  }
                  className="btn-social"
                  variant="twitter"
                  style={{
                    backgroundColor: "rgba(35,204,24,1)",
                    borderColor: "rgba(35,204,24,1)",
                  }}
                >
                  <i
                    className="fas fa-file-invoice-dollar"
                    style={{ marginRight: 10 }}
                  ></i>
                  Tambah Invoice
                </Button>
                {/* <Button
            onClick={() => {
              window.open(
                "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                "_blank"
              );
            }}
            className="btn-social"
            variant="twitter"
            style={{
              backgroundColor: "rgba(64,123,255,1)",
              borderColor: "rgba(64,123,255,1)",
              marginLeft: 10,
            }}
          >
            <i
              className="fas fa-cloud-download-alt"
              style={{ marginRight: 10 }}
            ></i>
            Download Format Import
          </Button> */}
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <InputGroup>
                      <Form.Control
                        style={{
                          paddingLeft: "10px",
                          width: "300px",
                        }}
                        type="text"
                        value={searchTextInv}
                        onChange={handleSearchInv}
                        placeholder="Kode Order/Nama Instansi/Nama Customer"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleClearSearchInv}
                        >
                          <i class="fas fa-times"></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <DataTable
                      columns={columnsInv}
                      data={listInvoice}
                      //   pagination
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalamanInv}
                      //   onChangePage={handlePageChange}
                      progressPending={pending}
                    />

                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  fontSize: 14,
                                }}
                              >
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChangeInv}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={pageInv}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={invoice.listInvoice.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="actives"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={pageInv}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPageInv(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          {/* Modal Detail Invoice */}
          <Modal
            size="lg"
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Col md="12" style={{ marginTop: 20 }}>
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Detail Invoice
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Kode Invoice
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailInvoice && detailInvoice.idInvoice}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Tanggal Invoice
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        moments
                          .tz(
                            new Date(detailInvoice.tglInvoice),
                            "Asia/Jakarta"
                          )
                          .format("DD-MM-YYYY")}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Tipe Pembayaran
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice && detailInvoice.tipePembayaran === "DP"
                        ? "DP"
                        : "Lunas"}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nama Admin
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice && detailInvoice.admin.namaLengkap}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Total Pembelian
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.totalPembelian)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal Admin
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.nominalAdmin)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal Peti
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.nominalPeti)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal Ongkir
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.nominalOngkir)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal Diskon
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.nominalDiskon)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal Bayar
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(
                          detailInvoice.totalPembelian +
                            detailInvoice.nominalAdmin +
                            detailInvoice.nominalPeti +
                            detailInvoice.nominalOngkir -
                            detailInvoice.nominalDiskon
                        )}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal DP
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.nominalDp)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal Lunas
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.nominalLunas)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nominal Sisa Bayar
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailInvoice &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailInvoice.nominalSisaBayar)}
                    </Col>
                  </Row>
                  <div style={{ marginTop: 20 }}>
                    <b>Bukti Dokumen Invoice</b>
                  </div>
                  <hr></hr>
                  <Row>
                    {detailInvoice &&
                      detailInvoice.buktiFoto.map((val, index) => {
                        return (
                          <>
                            <Col sm="4">
                              <Image
                                src={val}
                                style={{
                                  objectFit: "contain",

                                  width: "200px",
                                  height: "300px",
                                }}
                                thumbnail
                              />
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Modal>

          {/* Modal Tambah Invoice */}
          <Modal
            size="md"
            show={modalAddInv}
            onHide={() => {
              setModalAddInv(false);
              setNominalAdmin(0);
              setNominalPeti(0);
              setTmpNominalPeti(0);
              setNominalOngkir(0);
              setTmpNominalOngkir(0);
              setNominalDiskon(0);
              setNominalDp(0);
              setNominalLunas(0);
              setDokumen([]);
              setTipePembayaran("");
              setModalAddInv(false);
              setNominalSisaBayar(0);
              setEditedNominalOngkir(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Col md="12" style={{ marginTop: 20 }}>
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Form Tambah Invoice
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Total Harga Produk
                      </label>
                      <CurrencyInput
                        disabled
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Admin"
                        prefix="Rp "
                        // onValueChange={(value) => {
                        //   setNominalAdmin(value);
                        // }}
                        defaultValue={parseInt(
                          localStorage.getItem("totalPembelian")
                        )}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Admin
                      </label>
                      <CurrencyInput
                        disabled={newInvoice ? false : true}
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Admin"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalAdmin(value);
                          setNominalAdminValidasi(false);
                          // let tipeOrder = localStorage.getItem("tipeOrder");
                          // if (tipeOrder === "Online") {
                          let nominalBarang = parseInt(
                            localStorage.getItem("totalPembelian")
                          );
                          let nominalTotal =
                            nominalBarang +
                            parseInt(value) +
                            parseInt(nominalPeti) +
                            parseInt(nominalOngkir) -
                            parseInt(nominalDiskon);
                          let nominalLunass = nominalTotal;
                          setNominalLunas(nominalLunass);
                          setNominalSisaBayar(nominalLunass - nominalTotal);
                          // }
                        }}
                        defaultValue={nominalAdmin}
                      />
                      {nominalAdminValidasi ? (
                        <label style={{ textTransform: "none", color: "red" }}>
                          *nominal admin tidak boleh kosong
                        </label>
                      ) : null}
                    </Form.Group>

                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Peti
                      </label>
                      <CurrencyInput
                        disabled={tmpNominalPeti === 0 ? false : true}
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Peti"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalPeti(value);
                          setNominalPetiValidasi(false);
                          if (newInvoice === true) {
                            let nominalBarang = parseInt(
                              localStorage.getItem("totalPembelian")
                            );
                            let nominalTotal =
                              nominalBarang +
                              parseInt(nominalAdmin) +
                              parseInt(value) +
                              parseInt(nominalOngkir) -
                              parseInt(nominalDiskon);
                            let nominalLunass = nominalTotal;
                            setNominalLunas(nominalLunass);
                            setNominalSisaBayar(nominalLunass - nominalTotal);
                          } else {
                            setNominalLunas(
                              parseInt(nominalSisaBayar) + parseInt(value)
                            );
                          }
                        }}
                        defaultValue={nominalPeti}
                      />
                      {nominalPetiValidasi ? (
                        <label style={{ textTransform: "none", color: "red" }}>
                          *nominal peti tidak boleh kosong
                        </label>
                      ) : null}
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Ongkir
                      </label>
                      <CurrencyInput
                        disabled={tmpNominalOngkir === 0 ? false : true}
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Ongkir"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalOngkir(value);
                          setNominalOngkirValidasi(false);
                          setEditedNominalOngkir(true);
                          // if (tipePembayaran.value === "LN") {
                          if (newInvoice === true) {
                            let nominalBarang = parseInt(
                              localStorage.getItem("totalPembelian")
                            );
                            let nominalTotal =
                              nominalBarang +
                              parseInt(nominalAdmin) +
                              parseInt(nominalPeti) +
                              parseInt(value) -
                              parseInt(nominalDiskon);
                            let nominalLunass = nominalTotal;
                            setNominalLunas(nominalLunass);
                            setNominalSisaBayar(nominalLunass - nominalTotal);
                          } else {
                            setNominalLunas(
                              parseInt(nominalSisaBayar) + parseInt(value)
                            );
                          }

                          // setNominalLunas(
                          //   parseInt(nominalSisaBayar) + parseInt(value)
                          // );
                          // }
                        }}
                        defaultValue={nominalOngkir}
                      />
                      {nominalOngkirValidasi ? (
                        <label style={{ textTransform: "none", color: "red" }}>
                          *nominal ongkir tidak boleh kosong
                        </label>
                      ) : null}
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Diskon
                      </label>
                      <CurrencyInput
                        disabled={newInvoice ? false : true}
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Diskon"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalDiskon(value);
                          setNominalDiskonValidasi(false);
                          let nominalBarang = parseInt(
                            localStorage.getItem("totalPembelian")
                          );
                          let nominalTotal =
                            nominalBarang +
                            parseInt(nominalAdmin) +
                            parseInt(nominalPeti) +
                            parseInt(nominalOngkir) -
                            parseInt(value);
                          let nominalLunass = nominalTotal;
                          setNominalLunas(nominalLunass);
                          setNominalSisaBayar(nominalLunass - nominalTotal);
                        }}
                        defaultValue={nominalDiskon}
                      />
                      {nominalDiskonValidasi ? (
                        <label style={{ textTransform: "none", color: "red" }}>
                          *nominal diskon tidak boleh kosong
                        </label>
                      ) : null}
                    </Form.Group>
                    {newInvoice ? null : (
                      <Form.Group>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Nominal Sisa Bayar
                        </label>
                        <CurrencyInput
                          disabled={newInvoice ? false : true}
                          className="custom-input"
                          style={{
                            padding: 5,
                            borderRadius: 4,
                            width: "100%",
                            height: "40px",
                            borderWidth: "1px",
                            borderTopColor: "rgba(227, 227, 227, 0.3)",
                            borderLeftColor: "rgba(227, 227, 227, 0.3)",
                            borderBottomColor: "rgba(227, 227, 227, 1)",
                            borderRightColor: "rgba(227, 227, 227, 1)",
                          }}
                          name="nominal"
                          placeholder="Nominal Sisa Bayar"
                          prefix="Rp "
                          onValueChange={(value) => {
                            setNominalSisaBayar(value);
                          }}
                          defaultValue={nominalSisaBayar}
                        />
                      </Form.Group>
                    )}

                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Tipe Pembayaran
                      </label>

                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={tipePembayaran}
                        onChange={(value) => {
                          setTipePembayaran(value);
                          setTipePembayaranValidasi(false);
                          setNominalDpValidasi(false);
                          setNominalLunasValidasi(false);

                          // if (value.value === "DP") {
                          //   setNominalDp(0);
                          //   setNominalLunas(0);
                          // } else {
                          //   setNominalDp(0);
                          //   if (editedNominalOngkir === true) {
                          //     setNominalLunas(
                          //       parseInt(nominalSisaBayar) +
                          //         parseInt(nominalOngkir)
                          //     );
                          //   } else {
                          //     setNominalLunas(parseInt(nominalSisaBayar));
                          //   }
                          // }
                        }}
                        options={
                          localStorage.getItem("tipeOrder") === "Online"
                            ? [{ value: "LN", label: "Lunas" }]
                            : [
                                { value: "DP", label: "DP" },
                                { value: "LN", label: "Lunas" },
                              ]
                        }
                        placeholder="Pilih Tipe Pembayaran"
                      />
                      {tipePembayaranValidasi ? (
                        <label style={{ textTransform: "none", color: "red" }}>
                          *tipe pembayaran tidak boleh kosong
                        </label>
                      ) : null}
                    </Form.Group>
                    {tipePembayaran === "" ? null : tipePembayaran.value ===
                      "DP" ? (
                      <Form.Group>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Nominal DP
                        </label>
                        <CurrencyInput
                          className="custom-input"
                          style={{
                            padding: 5,
                            borderRadius: 4,
                            width: "100%",
                            height: "40px",
                            borderWidth: "1px",
                            borderTopColor: "rgba(227, 227, 227, 0.3)",
                            borderLeftColor: "rgba(227, 227, 227, 0.3)",
                            borderBottomColor: "rgba(227, 227, 227, 1)",
                            borderRightColor: "rgba(227, 227, 227, 1)",
                          }}
                          name="nominal"
                          placeholder="Nominal DP"
                          prefix="Rp "
                          onValueChange={(value) => {
                            setNominalDp(value);
                            setNominalDpValidasi(false);
                          }}
                          value={nominalDp}
                        />
                        {tipePembayaran.value === "DP" && nominalDpValidasi ? (
                          <label
                            style={{ textTransform: "none", color: "red" }}
                          >
                            *nominal DP tidak boleh kosong
                          </label>
                        ) : null}
                      </Form.Group>
                    ) : (
                      <Form.Group>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Nominal Lunas
                        </label>
                        <CurrencyInput
                          className="custom-input"
                          disabled
                          style={{
                            padding: 5,
                            borderRadius: 4,
                            width: "100%",
                            height: "40px",
                            borderWidth: "1px",
                            borderTopColor: "rgba(227, 227, 227, 0.3)",
                            borderLeftColor: "rgba(227, 227, 227, 0.3)",
                            borderBottomColor: "rgba(227, 227, 227, 1)",
                            borderRightColor: "rgba(227, 227, 227, 1)",
                          }}
                          name="nominal"
                          placeholder="Nominal Lunas"
                          prefix="Rp "
                          onValueChange={(value) => {
                            setNominalLunas(value);
                            setNominalLunasValidasi(false);
                          }}
                          value={nominalLunas}
                        />
                        {tipePembayaran.value === "LN" &&
                        nominalLunasValidasi ? (
                          <label
                            style={{ textTransform: "none", color: "red" }}
                          >
                            *nominal Lunas tidak boleh kosong atau 0
                          </label>
                        ) : null}
                      </Form.Group>
                    )}
                    <div>
                      <label
                        style={{
                          textTransform: "none",
                          color: "blue",
                          fontSize: 20,
                        }}
                      >
                        <b>
                          Harus dibayar :{" "}
                          {tipePembayaran.value === "DP"
                            ? new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(
                                tmpNominalOngkir === 0 && tmpNominalPeti === 0
                                  ? parseInt(nominalDp) +
                                      parseInt(nominalOngkir) +
                                      parseInt(nominalPeti)
                                  : nominalDp
                              )
                            : new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(nominalLunas)}
                        </b>
                      </label>
                    </div>
                    <div>
                      <div>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Upload Dokumen{" "}
                          <span
                            className="star"
                            style={{ textTransform: "none" }}
                          >
                            *{" "}
                            <span style={{ color: "blue" }}>
                              (Maksimal 2 Dokumen)
                            </span>
                          </span>
                        </label>
                      </div>

                      <label
                        className="label-input-file btn text-white mb-4"
                        htmlFor="import2"
                        style={{
                          fontWeight: "bold",
                          backgroundColor:
                            dokumen.length === 2 ? "grey" : "#447DF7",
                          border: 0,
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          <i
                            className="fas fa-images"
                            style={{ marginRight: 5 }}
                          ></i>
                          Pilih File
                        </div>

                        <input
                          disabled={
                            pending || dokumen.length === 2 ? true : false
                          }
                          type="file"
                          id="import2"
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          accept=".jpg,.jpeg,.png"
                          multiple
                        />
                      </label>
                      {dokumenValidasi === false ? null : (
                        <label
                          className="error"
                          style={{
                            textTransform: "none",
                            color: "red",
                            marginLeft: 10,
                          }}
                        >
                          Dokumen tidak boleh kosong.
                        </label>
                      )}
                      {dokumen.length === 0 ? (
                        <div>
                          <p style={{ color: "GrayText" }}>File kosong</p>
                        </div>
                      ) : (
                        dokumen.map((file, index) => (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <p className="mb-1 mr-1">{file.name}</p>

                            <i
                              className="fa fa-times"
                              style={{
                                cursor: "pointer",
                                color: "red",
                                marginLeft: 10,
                              }}
                              onClick={() =>
                                pending ? null : removeDokumen(index)
                              }
                            />
                          </div>
                        ))
                      )}
                    </div>
                  </Form>
                </Card.Body>
                <Card.Footer style={{ textAlign: "end" }}>
                  <Button
                    className="btn-fill"
                    type="submit"
                    // variant="info"
                    style={{ backgroundColor: "#00BFFF", border: 0 }}
                    onClick={buatInvoice}
                  >
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Modal>

          {/* Modal Edit Invoice Online */}
          <Modal
            size="md"
            show={modalEditInv}
            onHide={() => {
              setModalEditInv(false);
              setNominalAdmin(0);
              setNominalPeti(0);
              setTmpNominalPeti(0);
              setNominalOngkir(0);
              setTmpNominalOngkir(0);
              setNominalDiskon(0);
              setNominalDp(0);
              setNominalLunas(0);
              setDokumen([]);
              setTipePembayaran("");
              setModalAddInv(false);
              setNominalSisaBayar(0);
              setEditedNominalOngkir(false);
              setJasaOnline(0);
              setIdInvoice("");
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Col md="12" style={{ marginTop: 20 }}>
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Form Edit Invoice
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form action="#" method="#">
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Total Harga Produk
                      </label>
                      <CurrencyInput
                        disabled
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Admin"
                        prefix="Rp "
                        // onValueChange={(value) => {
                        //   setNominalAdmin(value);
                        // }}
                        defaultValue={parseInt(
                          localStorage.getItem("totalPembelian")
                        )}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Admin
                      </label>
                      <CurrencyInput
                        disabled
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Admin"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalAdmin(value);
                          let nominalBarang = parseInt(
                            localStorage.getItem("totalPembelian")
                          );
                          let nominalTotal =
                            nominalBarang +
                            parseInt(value) +
                            parseInt(nominalPeti) +
                            parseInt(nominalOngkir) -
                            parseInt(nominalDiskon);
                          let nominalLunass = nominalTotal;
                          setNominalLunas(nominalLunass);
                          setNominalSisaBayar(nominalLunass - nominalTotal);
                        }}
                        defaultValue={nominalAdmin}
                      />
                    </Form.Group>

                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Peti
                      </label>
                      <CurrencyInput
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Peti"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalPeti(value);
                          let nominalBarang = parseInt(
                            localStorage.getItem("totalPembelian")
                          );
                          let nominalTotal =
                            nominalBarang +
                            parseInt(nominalAdmin) +
                            parseInt(value) +
                            parseInt(nominalOngkir) -
                            parseInt(nominalDiskon);
                          let nominalLunass = nominalTotal;
                          setNominalLunas(nominalLunass);
                          setNominalSisaBayar(nominalLunass - nominalTotal);
                        }}
                        defaultValue={nominalPeti}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Ongkir
                      </label>
                      <CurrencyInput
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Ongkir"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalOngkir(value);
                          let nominalBarang = parseInt(
                            localStorage.getItem("totalPembelian")
                          );
                          let nominalTotal =
                            nominalBarang +
                            parseInt(nominalAdmin) +
                            parseInt(nominalPeti) +
                            parseInt(value) -
                            parseInt(nominalDiskon);
                          let nominalLunass = nominalTotal;
                          setNominalLunas(nominalLunass);
                          setNominalSisaBayar(nominalLunass - nominalTotal);
                        }}
                        defaultValue={nominalOngkir}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nominal Diskon
                      </label>
                      <CurrencyInput
                        disabled
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Nominal Diskon"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setNominalDiskon(value);
                          let nominalBarang = parseInt(
                            localStorage.getItem("totalPembelian")
                          );
                          let nominalTotal =
                            nominalBarang +
                            parseInt(nominalAdmin) +
                            parseInt(nominalPeti) +
                            parseInt(nominalOngkir) -
                            parseInt(value);
                          let nominalLunass = nominalTotal;
                          setNominalLunas(nominalLunass);
                          setNominalSisaBayar(nominalLunass - nominalTotal);
                        }}
                        defaultValue={nominalDiskon}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Jasa Online
                      </label>
                      <CurrencyInput
                        className="custom-input"
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          width: "100%",
                          height: "40px",
                          borderWidth: "1px",
                          borderTopColor: "rgba(227, 227, 227, 0.3)",
                          borderLeftColor: "rgba(227, 227, 227, 0.3)",
                          borderBottomColor: "rgba(227, 227, 227, 1)",
                          borderRightColor: "rgba(227, 227, 227, 1)",
                        }}
                        name="nominal"
                        placeholder="Jasa Online"
                        prefix="Rp "
                        onValueChange={(value) => {
                          setJasaOnline(value);
                        }}
                        defaultValue={jasaOnline}
                      />
                    </Form.Group>
                    {newInvoice ? null : (
                      <Form.Group>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Nominal Sisa Bayar
                        </label>
                        <CurrencyInput
                          disabled
                          className="custom-input"
                          style={{
                            padding: 5,
                            borderRadius: 4,
                            width: "100%",
                            height: "40px",
                            borderWidth: "1px",
                            borderTopColor: "rgba(227, 227, 227, 0.3)",
                            borderLeftColor: "rgba(227, 227, 227, 0.3)",
                            borderBottomColor: "rgba(227, 227, 227, 1)",
                            borderRightColor: "rgba(227, 227, 227, 1)",
                          }}
                          name="nominal"
                          placeholder="Nominal Sisa Bayar"
                          prefix="Rp "
                          onValueChange={(value) => {
                            setNominalSisaBayar(value);
                          }}
                          value={nominalSisaBayar}
                        />
                      </Form.Group>
                    )}

                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Tipe Pembayaran
                      </label>

                      <Select
                        disabled
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={tipePembayaran}
                        onChange={(value) => {
                          setTipePembayaran(value);
                        }}
                        options={
                          localStorage.getItem("tipeOrder") === "Online"
                            ? [{ value: "LN", label: "Lunas" }]
                            : [
                                { value: "DP", label: "DP" },
                                { value: "LN", label: "Lunas" },
                              ]
                        }
                        placeholder="Pilih Tipe Pembayaran"
                      />
                    </Form.Group>
                    {tipePembayaran === "" ? null : tipePembayaran.value ===
                      "DP" ? (
                      <Form.Group>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Nominal DP
                        </label>
                        <CurrencyInput
                          className="custom-input"
                          style={{
                            padding: 5,
                            borderRadius: 4,
                            width: "100%",
                            height: "40px",
                            borderWidth: "1px",
                            borderTopColor: "rgba(227, 227, 227, 0.3)",
                            borderLeftColor: "rgba(227, 227, 227, 0.3)",
                            borderBottomColor: "rgba(227, 227, 227, 1)",
                            borderRightColor: "rgba(227, 227, 227, 1)",
                          }}
                          name="nominal"
                          placeholder="Nominal DP"
                          prefix="Rp "
                          onValueChange={(value) => {
                            setNominalDp(value);
                          }}
                          value={nominalDp}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Nominal Lunas
                        </label>
                        <CurrencyInput
                          disabled
                          className="custom-input"
                          style={{
                            padding: 5,
                            borderRadius: 4,
                            width: "100%",
                            height: "40px",
                            borderWidth: "1px",
                            borderTopColor: "rgba(227, 227, 227, 0.3)",
                            borderLeftColor: "rgba(227, 227, 227, 0.3)",
                            borderBottomColor: "rgba(227, 227, 227, 1)",
                            borderRightColor: "rgba(227, 227, 227, 1)",
                          }}
                          name="nominal"
                          placeholder="Nominal Lunas"
                          prefix="Rp "
                          onValueChange={(value) => {
                            setNominalLunas(value);
                          }}
                          value={nominalLunas}
                        />
                      </Form.Group>
                    )}
                    <div>
                      <label
                        style={{
                          textTransform: "none",
                          color: "blue",
                          fontSize: 20,
                        }}
                      >
                        <b>
                          Harus dibayar :{" "}
                          {tipePembayaran.value === "DP"
                            ? new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(
                                tmpNominalOngkir === 0 && tmpNominalPeti === 0
                                  ? parseInt(nominalDp) +
                                      parseInt(nominalOngkir) +
                                      parseInt(nominalPeti)
                                  : nominalDp
                              )
                            : new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(nominalLunas)}
                        </b>
                      </label>
                    </div>
                    <div>
                      <div>
                        <label style={{ color: "black", fontWeight: "bold" }}>
                          Upload Dokumen{" "}
                          <span
                            className="star"
                            style={{ textTransform: "none" }}
                          >
                            *{" "}
                            <span style={{ color: "blue" }}>
                              (Maksimal 2 Dokumen)
                            </span>
                          </span>
                        </label>
                      </div>

                      <label
                        className="label-input-file btn text-white mb-4"
                        htmlFor="import2"
                        style={{
                          fontWeight: "bold",
                          backgroundColor:
                            dokumen.length === 2 ? "grey" : "#447DF7",
                          border: 0,
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          <i
                            className="fas fa-images"
                            style={{ marginRight: 5 }}
                          ></i>
                          Pilih File
                        </div>

                        <input
                          disabled={
                            pending || dokumen.length === 2 ? true : false
                          }
                          type="file"
                          id="import2"
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          accept=".jpg,.jpeg,.png"
                          multiple
                        />
                      </label>
                      {dokumenValidasi === false ? null : (
                        <label
                          className="error"
                          style={{
                            textTransform: "none",
                            color: "red",
                            marginLeft: 10,
                          }}
                        >
                          Dokumen tidak boleh kosong.
                        </label>
                      )}
                      {dokumen.length === 0 ? (
                        <div>
                          <p style={{ color: "GrayText" }}>File kosong</p>
                        </div>
                      ) : (
                        dokumen.map((file, index) => (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <p className="mb-1 mr-1">{file.name}</p>

                            <i
                              className="fa fa-times"
                              style={{
                                cursor: "pointer",
                                color: "red",
                                marginLeft: 10,
                              }}
                              onClick={() =>
                                pending ? null : removeDokumen(index)
                              }
                            />
                          </div>
                        ))
                      )}
                    </div>
                  </Form>
                </Card.Body>
                <Card.Footer style={{ textAlign: "end" }}>
                  <Button
                    className="btn-fill"
                    type="submit"
                    // variant="info"
                    style={{ backgroundColor: "#00BFFF", border: 0 }}
                    onClick={editInvoice}
                  >
                    Edit
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Modal>
        </>
      ) : (
        //List Order
        <>
          <Container fluid>
            <Row
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Col>
                <div style={{ fontSize: 25, fontWeight: "bold" }}>
                  List Order
                </div>
              </Col>
              <Col style={{ textAlign: "right" }}>
                {/* <Button
            style={{ marginLeft: 20, marginBottom: 0 }}
            onClick={() => {
              setModal(true);
            }}
          >
            Tambah Produk
          </Button> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <InputGroup>
                      <Form.Control
                        style={{
                          paddingLeft: "10px",
                          width: "300px",
                        }}
                        type="text"
                        value={searchText}
                        onChange={handleSearch}
                        placeholder="Kode Order/Nama Instansi/Nama Customer"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleClearSearch}
                        >
                          <i class="fas fa-times"></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
        <Col>
          <Button
            onClick={() => {
              setModalImport(true);
            }}
            className="btn-social"
            variant="twitter"
            style={{
              backgroundColor: "rgba(40,118,74,1)",
              borderColor: "rgba(40,118,74,1)",
            }}
          >
            <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
            Import Excel
          </Button>
          <Button
            onClick={() => {
              window.open(
                "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                "_blank"
              );
            }}
            className="btn-social"
            variant="twitter"
            style={{
              backgroundColor: "rgba(64,123,255,1)",
              borderColor: "rgba(64,123,255,1)",
              marginLeft: 10,
            }}
          >
            <i
              className="fas fa-cloud-download-alt"
              style={{ marginRight: 10 }}
            ></i>
            Download Format Import
          </Button>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <div>
              <InputGroup>
                <Form.Control
                  style={{
                    paddingLeft: "10px",
                    width: "300px",
                  }}
                  type="text"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="Nama Mesin/Kode Mesin"
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    onClick={handleClearSearch}
                  >
                    <i class="fas fa-times"></i>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row> */}

            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <DataTable
                      columns={columns}
                      data={listOrder}
                      //   pagination
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      //   onChangePage={handlePageChange}
                      progressPending={pending}
                    />

                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  fontSize: 14,
                                }}
                              >
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={order.listOrderProduk.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="actives"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          {/* Modal Detail Order */}
          <Modal
            size="lg"
            show={modalOrder}
            onHide={() => {
              setModalOrder(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Col md="12" style={{ marginTop: 20 }}>
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Detail Order
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Kode Order
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailOrder && detailOrder.idSo}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Instansi
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailOrder && detailOrder.customerInstansi}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nama Customer
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailOrder && detailOrder.namaCustomer}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Alamat
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailOrder && detailOrder.alamatCustomer}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      No Telpn
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailOrder && detailOrder.noTelp}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Email
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailOrder && detailOrder.email === ""
                        ? "-"
                        : detailOrder && detailOrder.email}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Tanggal Order
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailOrder &&
                        moments
                          .tz(new Date(detailOrder.tglOrder), "Asia/Jakarta")
                          .format("DD-MM-YYYY")}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nama Sales
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailOrder && detailOrder.sales.namaLengkap}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Status Order
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailOrder && detailOrder.statusSo === "Ajukan" ? (
                        <b style={{ color: "rgb(255,163,47)" }}>Diorder</b>
                      ) : detailOrder && detailOrder.statusSo === "Proses" ? (
                        <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
                      ) : detailOrder && detailOrder.statusSo === "Tolak" ? (
                        <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
                      ) : detailOrder && detailOrder.statusSo === "Kirim" ? (
                        <b style={{ color: "rgb(68,125,247)" }}>Dikirim</b>
                      ) : detailOrder && detailOrder.statusSo === "Belum" ? (
                        <b style={{ color: "rgb(0,0,0)" }}>Belum Dikirim</b>
                      ) : detailOrder && detailOrder.statusSo === "Pending" ? (
                        <b style={{ color: "rgb(247, 0, 239)" }}>Pending</b>
                      ) : detailOrder && detailOrder.statusSo === "Diambil" ? (
                        <b style={{ color: "rgb(36,204,23)" }}>Diambil</b>
                      ) : detailOrder && detailOrder.statusSo === "Cancel" ? (
                        <b style={{ color: "rgb(251,59,70)" }}>Cancel</b>
                      ) : (
                        <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Tipe Order
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailOrder && detailOrder.tipeSo}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Status Pembayaran
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {(detailOrder && !detailOrder.statusPembayarna) ||
                      (detailOrder && detailOrder.statusPembayarna === "") ? (
                        <span>
                          <b>-</b>
                        </span>
                      ) : detailOrder &&
                        detailOrder.statusPembayarna === "Pending" ? (
                        <span style={{ color: "rgb(255,163,47)" }}>
                          <b>Pending</b>
                        </span>
                      ) : detailOrder &&
                        detailOrder.statusPembayarna === "Proses" ? (
                        <span style={{ color: "rgb(68,125,247)" }}>
                          <b>Proses</b>
                        </span>
                      ) : (
                        <span style={{ color: "rgb(36,204,23)" }}>
                          <b>Lunas</b>
                        </span>
                      )}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Status Lunas
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailOrder && detailOrder.statusLunas === false ? (
                        <span style={{ color: "rgb(251,59,70)" }}>
                          <b>Belum Lunas</b>
                        </span>
                      ) : (
                        <span style={{ color: "rgb(36,204,23)" }}>
                          <b>Lunas</b>
                        </span>
                      )}
                    </Col>
                  </Row> */}
                  <div style={{ marginTop: 20 }}>
                    <b>Bukti Dokumen Order</b>
                  </div>
                  <hr></hr>
                  <Row>
                    {detailOrder &&
                      detailOrder.buktiFoto.map((val, index) => {
                        return (
                          <>
                            <Col sm="4">
                              <Image
                                src={val}
                                style={{
                                  objectFit: "contain",

                                  width: "200px",
                                  height: "300px",
                                }}
                                thumbnail
                              />
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                  <div style={{ marginTop: 20 }}>
                    <b>Data Pembelian</b>
                  </div>
                  <hr></hr>
                  <div style={{ overflowX: "auto" }}>
                    <table border="1" cellPadding="8" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Kode Produk</th>
                          <th>Nama Produk</th>
                          <th>Tipe Produk</th>
                          <th>Kategori Produk</th>
                          <th>Kapasitas Produk</th>
                          <th>Sumber Produk</th>
                          <th>Tanggal Awal Pengerjaan</th>
                          <th>Tanggal Deadline</th>
                          <th>Tanggal Selesai</th>
                          <th>Status Produk</th>
                          <th>Harga Satuan</th>
                          <th>Qty</th>
                          <th>Selesai</th>
                          <th>Total Harga</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailOrder &&
                          detailOrder.orders.map((item, index) => {
                            return (
                              <tr
                                key={item.produk.kodeProduk}
                                style={{
                                  backgroundColor:
                                    item.qty != item.qtySelesai
                                      ? "rgba(255, 3, 3,0.3)"
                                      : null,
                                }}
                              >
                                <td style={{ textAlign: "center" }}>
                                  {index + 1}
                                </td>
                                <td>{item.produk.kodeProduk}</td>
                                <td>{item.produk.namaProduk}</td>
                                <td>{item.produk.tipe}</td>
                                <td>
                                  {item.produk.kategoriProduk === "upgrade"
                                    ? "UPGRADE"
                                    : "UTAMA"}
                                </td>
                                <td>{item.produk.kapasitas}</td>
                                <td>
                                  {item.sumberProduk === "PL"
                                    ? "Pesan Langsung"
                                    : "Gudang"}
                                </td>
                                <td>
                                  {!item.tglAwalPengerjaan ||
                                  item.tglAwalPengerjaan === ""
                                    ? "-"
                                    : moments
                                        .tz(
                                          new Date(item.tglAwalPengerjaan),
                                          "Asia/Jakarta"
                                        )
                                        .format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {!item.tglDeadline || item.tglDeadline === ""
                                    ? "-"
                                    : moments
                                        .tz(
                                          new Date(item.tglDeadline),
                                          "Asia/Jakarta"
                                        )
                                        .format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {!item.tglSelesai || item.tglSelesai === ""
                                    ? "-"
                                    : moments
                                        .tz(
                                          new Date(item.tglSelesai),
                                          "Asia/Jakarta"
                                        )
                                        .format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {item.statusProduk === "" ||
                                  !item.statusProduk
                                    ? "-"
                                    : item.statusProduk}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.produk.hargaProduk)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.qty}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.qtySelesai}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.totalHarga)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="14">
                            <b>Total Pembelian</b>
                          </td>
                          <td>
                            <b>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(
                                detailOrder && detailOrder.totalPembelian
                              )}
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <b>Data Invoice</b>
                  </div>
                  <hr></hr>
                  <div style={{ overflowX: "auto" }}>
                    <table border="1" cellPadding="8" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>No Invoice</th>
                          <th>Tgl Invoice</th>
                          <th>Tipe Pembayaran</th>
                          <th>Nama Admin</th>
                          <th>Total Pembelian</th>
                          <th>Nominal Admin</th>
                          <th>Nominal Peti</th>
                          <th>Nominal Ongkir</th>
                          <th>Nominal Diskon</th>
                          <th>Nominal Bayar</th>
                          <th>Nominal DP</th>
                          <th>Nominal Lunas</th>
                          <th>Nominal Sisa Bayar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailOrder && detailOrder.invoices.length === 0 ? (
                          <tr>
                            <td colSpan="14">
                              <span style={{ color: "grey" }}>
                                Belum memiliki data invoice
                              </span>
                            </td>
                          </tr>
                        ) : (
                          detailOrder &&
                          detailOrder.invoices.map((item, index) => {
                            return (
                              <tr key={item.idInvoice}>
                                <td style={{ textAlign: "center" }}>
                                  {index + 1}
                                </td>
                                <td>{item.idInvoice}</td>
                                <td>
                                  {moments
                                    .tz(
                                      new Date(item.tglInvoice),
                                      "Asia/Jakarta"
                                    )
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>{item.tipePembayaran}</td>
                                <td>{item.user.namaLengkap}</td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.totalPembelian)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalAdmin)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalPeti)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalOngkir)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalDiskon)}
                                </td>
                                <td>
                                  {index === 0
                                    ? new Intl.NumberFormat("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                        minimumFractionDigits: 0,
                                      }).format(
                                        item.totalPembelian +
                                          item.nominalAdmin +
                                          item.nominalPeti +
                                          item.nominalOngkir -
                                          item.nominalDiskon
                                      )
                                    : item.tipePembayaran === "DP"
                                    ? new Intl.NumberFormat("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                        minimumFractionDigits: 0,
                                      }).format(item.nominalDp)
                                    : new Intl.NumberFormat("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                        minimumFractionDigits: 0,
                                      }).format(item.nominalLunas)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalDp)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalLunas)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalSisaBayar)}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <b>Data Surat Jalan</b>
                  </div>
                  <hr></hr>
                  <div style={{ overflowX: "auto" }}>
                    <table border="1" cellPadding="8" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Kode Surat Jalan</th>
                          <th>Nomor Resi</th>
                          <th>Nama Admin</th>
                          <th>Tanggal Surat</th>
                          <th>Keterangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailOrder && detailOrder.suratJalan.length === 0 ? (
                          <tr>
                            <td colSpan="14">
                              <span style={{ color: "grey" }}>
                                Belum memiliki data surat jalan
                              </span>
                            </td>
                          </tr>
                        ) : (
                          detailOrder &&
                          detailOrder.suratJalan.map((item, index) => {
                            return (
                              <tr key={item.idSuratJalan}>
                                <td style={{ textAlign: "center" }}>
                                  {index + 1}
                                </td>
                                <td>{item.idSuratJalan}</td>
                                <td>{item.nomorResi}</td>
                                <td>{item.user.namaLengkap}</td>
                                <td>
                                  {moments
                                    .tz(new Date(item.tglSurat), "Asia/Jakarta")
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>{item.keterangan}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <b>Bukti Dokumen Surat Jalan</b>
                  </div>
                  <hr></hr>
                  <Row>
                    {detailOrder && detailOrder.suratJalan.length === 0 ? (
                      <span style={{ color: "grey", marginLeft: 20 }}>
                        Belum memiliki dokumen surat jalan.
                      </span>
                    ) : (
                      detailOrder &&
                      detailOrder.suratJalan[0].buktiFoto.map((val, index) => {
                        return (
                          <>
                            <Col sm="4">
                              <Image
                                src={val}
                                style={{
                                  objectFit: "contain",

                                  width: "200px",
                                  height: "300px",
                                }}
                                thumbnail
                              />
                            </Col>
                          </>
                        );
                      })
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Modal>
        </>
      )}
    </>
  );
}

export default InvoiceOrder;
