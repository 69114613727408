const initialState = {
  listInvoice: [],
};

const invoiceReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLINVOICE":
      return {
        ...state,
        listInvoice: data,
      };
    default:
      return state;
  }
};
export default invoiceReducer;
