import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { getPesanLangsung, updatePesanLangsung } from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";
import NotificationAlert from "react-notification-alert";

function PesanLangsung() {
  const dispatch = useDispatch();
  const pesanLangsung = useSelector((state) => state.pesanLangsungReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listPl, setListPl] = React.useState([]);
  const [statusPesan, setStatusPesan] = React.useState({
    value: "",
    label: "Semua",
  });
  const notificationAlertRef = React.useRef(null);

  const [modal, setModal] = React.useState(false);
  const [modalUpdatePl, setModalUpdatePl] = React.useState(false);
  const [detailPl, setDetailPl] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [selesai, setSelesai] = React.useState(1);

  let incSelesai = () => {
    if (selesai + 1 > detailPl.qty - detailPl.qtySelesai) {
      null;
    } else {
      setSelesai(selesai + 1);
    }
  };
  let decSelesai = () => {
    if (selesai - 1 === 0) {
      null;
    } else {
      setSelesai(selesai - 1);
    }
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.qty != row.qtySelesai,
      style: {
        backgroundColor: "rgba(255, 3, 3, 0.15)",
        color: "#000000",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + pesanLangsung.listPl.pagingCounter,
    },
    {
      name: "Kode Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.produk.kodeProduk,
    },
    {
      name: "Nama Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.produk.namaProduk,
    },
    {
      name: "Kapasitas",
      width: "200px",
      center: true,
      selector: (row) => row.produk.kapasitas,
    },
    {
      name: "Tipe Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.produk.tipe,
    },
    {
      name: "Jumlah Pesan",
      width: "200px",
      center: true,
      selector: (row) => row.qty,
    },
    {
      name: "Jumlah Selesai",
      width: "200px",
      center: true,
      selector: (row) => row.qtySelesai,
    },
    {
      name: "Instansi",
      width: "200px",
      center: true,
      selector: (row) => row.order.customerInstansi,
    },
    {
      name: "Nama Customer",
      width: "200px",
      center: true,
      selector: (row) => row.order.namaCustomer,
    },

    {
      name: "Kode SO",
      width: "200px",
      center: true,
      selector: (row) => row.idSo,
    },
    {
      name: "Status Pengerjaan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusProduk === "Progress" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
          </div>
        ) : !row.statusProduk ? (
          <div
          // style={{
          //   backgroundColor: "rgba(36,204,239,0.2)",
          //   padding: "5px 10px 5px 10px",
          //   borderRadius: 5,
          //   border: "1px solid rgb(36,204,239)",
          // }}
          >
            <b>-</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
          </div>
        ),
    },
    {
      name: "Tanggal Mulai",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.tglAwalPengerjaan ? (
          <b>-</b>
        ) : (
          moments
            .tz(new Date(row.tglAwalPengerjaan), "Asia/Jakarta")
            .format("DD-MM-YYYY")
        ),
    },
    {
      name: "Tanggal Selesai",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.tglSelesai || row.tglSelesai === "" ? (
          <b>-</b>
        ) : (
          moments
            .tz(new Date(row.tglSelesai), "Asia/Jakarta")
            .format("DD-MM-YYYY")
        ),
    },
    {
      name: "Deadline",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.tglDeadline ? (
          <b>-</b>
        ) : (
          moments
            .tz(new Date(row.tglDeadline), "Asia/Jakarta")
            .format("DD-MM-YYYY")
        ),
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setModal(true);
                  setDetailPl(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {auth.responLogin.role === "Super Admin" ||
              auth.responLogin.role === "Admin" ||
              auth.responLogin.role === "Management" ||
              auth.responLogin.role === "Logistik" ? (
                <div
                  onClick={() => {
                    row.statusProduk === "Selesai"
                      ? null
                      : !row.tglAwalPengerjaan
                      ? Swal.fire({
                          title: "Tidak Dapat Update Data",
                          text: "Data tidak dapat diupdate karena belum memiliki invoice",
                          icon: "warning",
                        })
                      : setModalUpdatePl(true);
                    setDetailPl(row);
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor:
                      row.statusProduk === "Selesai" ? "unset" : "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-48903503">Update Selesai</Tooltip>
                    }
                  >
                    <i
                      style={{
                        color:
                          row.statusProduk === "Selesai" ? "grey" : "#478964",
                        fontSize: 16,
                      }}
                      className="fas fa-edit"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const history = useHistory();
  const updatePl = async () => {
    Swal.fire({
      title: `Apakah anda yakin untuk mengupdate selesai?`,
      text: `${detailPl.idSo}-${detailPl.produk.kodeProduk}-${detailPl.produk.namaProduk}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Update!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...!",
          text: "Update data sedang berlangsung,tunggu proses hingga selesai!",
          icon: "warning",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        updatePesanLangsung(dispatch, {
          idOrderan: detailPl.idOrderan,
          selesai: selesai,
        }).then((response) => {
          setModalUpdatePl(false);
          getPesanLangsung(dispatch, {
            cari: searchText,
            page: page + 1,
            limit: totalPerHalaman,
          });
          Swal.fire({
            title: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };

  React.useEffect(() => {
    let tmp =
      pesanLangsung.listPl.data &&
      pesanLangsung.listPl.data.map((val, index) => {
        return {
          ...val,
        };
      });

    setListPl(tmp);

    if (pesanLangsung.listPl.length != 0) {
      setPending(false);
    }
  }, [pesanLangsung.listPl]);

  React.useEffect(() => {
    getPesanLangsung(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
      statusProduk: statusPesan.value,
    });
  }, [page, totalPerHalaman, searchText, statusPesan]);

  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>
              Pesan Langsung
            </div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Kode Order/Nama Instansi/Nama Customer"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            marginBottom: 20,

            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 10 }}>
            <label style={{ marginBottom: 0 }}>Status Pengerjaan</label>
          </div>
          <div style={{ width: 150 }}>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="singleSelect"
              value={statusPesan}
              onChange={(value) => {
                setStatusPesan(value);
              }}
              options={[
                { value: "", label: "Semua" },
                { value: "Progress", label: "Diproses" },
                { value: "Selesai", label: "Selesai" },
              ]}
              placeholder="Pilih Status"
            />
          </div>
        </div>
        {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listPl}
                  //   pagination
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  //   onChangePage={handlePageChange}
                  progressPending={pending}
                  conditionalRowStyles={conditionalRowStyles}
                />

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "bold",
                              textTransform: "none",
                              fontSize: 14,
                            }}
                          >
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={pesanLangsung.listPl.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        initialPage={page}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Detail */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Pesan Langsung
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kode Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.idSo}</Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kode Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.produk.kodeProduk}</Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.produk.namaProduk}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tipe Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.produk.tipe}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kapasitas Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.produk.kapasitas}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Jumlah Pesan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.qty}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Jumlah Selesai
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.qtySelesai}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Instansi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.order.customerInstansi}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Customer
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.order.namaCustomer}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Alamat
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.order.alamatCustomer}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  No Telpn
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPl && detailPl.order.noTelp}</Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Mulai Pengerjaan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPl && !detailPl.tglAwalPengerjaan
                    ? "-"
                    : detailPl &&
                      moments
                        .tz(
                          new Date(detailPl.tglAwalPengerjaan),
                          "Asia/Jakarta"
                        )
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Deadline Pengerjaan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPl && !detailPl.tglDeadline
                    ? "-"
                    : detailPl &&
                      moments
                        .tz(new Date(detailPl.tglDeadline), "Asia/Jakarta")
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Selesai
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {(detailPl && !detailPl.tglSelesai) ||
                  (detailPl && detailPl.tglSelesai === "")
                    ? "-"
                    : detailPl &&
                      moments
                        .tz(new Date(detailPl.tglSelesai), "Asia/Jakarta")
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Pengerjaan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPl && detailPl.statusProduk === "Progress" ? (
                    <div
                      style={{
                        backgroundColor: "rgba(36,204,239,0.2)",
                        padding: "5px 10px 5px 10px",
                        borderRadius: 5,
                        border: "1px solid rgb(36,204,239)",
                        width: 100,
                        textAlign: "center",
                      }}
                    >
                      <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
                    </div>
                  ) : detailPl && !detailPl.statusProduk ? (
                    "-"
                  ) : (
                    <div
                      style={{
                        backgroundColor: "rgba(36,204,23,0.2)",
                        padding: "5px 10px 5px 10px",
                        borderRadius: 5,
                        border: "1px solid rgb(36,204,23)",
                        width: 100,
                        textAlign: "center",
                      }}
                    >
                      <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="sm"
        show={modalUpdatePl}
        onHide={() => {
          setModalUpdatePl(false);
          setSelesai(1);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Card className="stacked-form" style={{ margin: 10 }}>
          <Card.Header>
            <Card.Title as="h4" style={{ color: "black", fontWeight: "bold" }}>
              Update Mesin Selesai
              <hr></hr>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              Mesin dipesan : <b>{detailPl && detailPl.qty}</b>
            </div>
            <div>
              Mesin selesai : <b>{detailPl && detailPl.qtySelesai}</b>
            </div>
            <div>
              Mesin belum selesai :
              <b>{detailPl && detailPl.qty - detailPl.qtySelesai}</b>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // backgroundColor: "red",
                textAlign: "center",
                justifyContent: "center",
                marginTop: 20,
                alignItems: "center",
              }}
            >
              <div>
                <Button
                  variant="info"
                  onClick={decSelesai}
                  style={{ color: "#000000" }}
                >
                  <b>-</b>
                </Button>
              </div>
              <div style={{ width: 80 }}>
                <span>{selesai}</span>
              </div>
              <div>
                <Button
                  variant="info"
                  onClick={incSelesai}
                  style={{ color: "#000000" }}
                >
                  <b>+</b>
                </Button>
              </div>
            </div>
          </Card.Body>

          <Card.Footer style={{ textAlign: "center" }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                updatePl();
              }}
              className="btn-wd"
              type="submit"
              // variant="info"
            >
              Update
            </Button>
          </Card.Footer>
        </Card>
      </Modal>
    </>
  );
}

export default PesanLangsung;
