import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getRole(dispatch, data, history) {
  try {
    const response = await baseAxios("/roleWeb/getRole", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLROLE", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahRole(data) {
  try {
    const response = await baseAxios.post("/roleWeb/addRole", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateRole(data) {
  try {
    const response = await baseAxios.patch("/roleWeb/updateRole", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteRole(data) {
  try {
    const response = await baseAxios.patch("/roleWeb/deleteRole", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
