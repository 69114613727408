const initialState = {
  listJabatan: [],
};

const jabatanReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLJABATAN":
      return {
        ...state,
        listJabatan: data,
      };
    default:
      return state;
  }
};
export default jabatanReducer;
