import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

function getRouting(routes, val) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].name === val) {
      return routes[i].layout + routes[i].path;
    }
    if (routes[i].collapse) {
      return getRouting(routes[i].views, val);
    }
  }
  return "/";
}

export function login(dispatch, data, history) {
  // Swal.fire({
  //   title: "Tunggu ...",
  //   didOpen() {
  //     Swal.showLoading();
  //   },
  //   allowOutsideClick: false,
  //   allowEscapeKey: false,
  //   allowEnterKey: false,
  // });
  if (data.username === "" || data.password === "") {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Maaf username atau password tidak boleh kosong",
    });
  } else {
    baseAxios
      .post("/userWeb/login", data)
      .then(async (respon) => {
        Swal.close();
        localStorage.setItem("token", respon.data.token);
        dispatch({
          type: "SET_IDENTITY",
          data: respon.data,
        });
        history.push("/admin/dashboards");
      })
      .catch(errorHandler);
  }
}

export function refresh(dispatch) {
  Swal.fire({
    title: "Tunggu ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get("/userWeb/refresh", {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      localStorage.setItem("token", respon.data.token);
      dispatch({
        type: "SET_IDENTITY",
        data: respon.data,
      });
      Swal.close();
    })
    .catch(errorHandler);
}

export function logout() {
  localStorage.removeItem("token");
  window.location.replace("/auth/login-page");
}

export async function getProfile(dispatch, data) {
  try {
    const response = await baseAxios.post("/userWeb/getProfile", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    dispatch({ type: "SET_PROFILE", data: response.data.data });
  } catch (err) {
    return err.response;
  }
}

export async function ubahFotoProfile(data) {
  try {
    const response = await baseAxios.patch("/userWeb/updateFotoProfile", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function ubahPassword(data) {
  try {
    const response = await baseAxios.post("/userWeb/ubahPassword", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
