import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import divisiReducer from "./divisi";
import jabatanReducer from "./jabatan";
import roleReducer from "./role";
import karyawanReducer from "./karyawan";
import produkReducer from "./produk";
import orderReducer from "./order";
import invoiceReducer from "./invoice";
import suratJalanReducer from "./suratJalan";
import pesanLangsungReducer from "./pesanLangsung";
import poReducer from "./po";

// function
export * from "./auth/function";
export * from "./divisi/function";
export * from "./jabatan/function";
export * from "./role/function";
export * from "./karyawan/function";
export * from "./produk/function";
export * from "./order/function";
export * from "./invoice/function";
export * from "./suratJalan/function";
export * from "./pesanLangsung/function";
export * from "./dashboard/function";
export * from "./po/function";
export * from "./errorHandler";
// server api
// export const baseUrl = process.env.REACT_APP_URL
export const baseUrl = "https://apibackoffice.baserjp.com/";
// export const baseUrl = "http://192.168.1.19:9030/";
// export const baseUrl = "http://192.168.110.160:9030/";
// export const baseUrl = "http://192.168.100.40:9030/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  // timeout: 10000,
});

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  divisiReducer,
  jabatanReducer,
  roleReducer,
  karyawanReducer,
  produkReducer,
  orderReducer,
  invoiceReducer,
  suratJalanReducer,
  pesanLangsungReducer,
  dashboardReducer,
  poReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
