const initialState = {
  listPoProses: [],
  listPoSelesai: [],
};

const poReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_POPROSES":
      return {
        ...state,
        listPoProses: data,
      };
    case "SET_POSELESAI":
      return {
        ...state,
        listPoSelesai: data,
      };
    default:
      return state;
  }
};
export default poReducer;
