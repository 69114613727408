import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Badge,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { getProduk, orderProduk, getProdukUpgrade } from "stores";
import "./Pagination.css";
import NotificationAlert from "react-notification-alert";

function CardProduk() {
  const dispatch = useDispatch();
  const produk = useSelector((state) => state.produkReducer);
  const [listProduk, setListProduk] = React.useState([]);
  const [listProdukUpgrade, setListProdukUpgrade] = React.useState([]);
  const [keranjang, setKeranjang] = React.useState([]);
  const [keranjangUpgrade, setKeranjangUpgrade] = React.useState([]);
  const [showKeranjang, setShowKeranjang] = React.useState(false);
  const [instansiCustomer, setInstansiCustomer] = React.useState("");
  const [namaCustomer, setNamaCustomer] = React.useState("");
  const [alamatCustomer, setAlamatCustomer] = React.useState("");
  const [notelpCustomer, setNotelpCustomer] = React.useState("");
  const [emailCustomer, setEmailCustomer] = React.useState("");
  const [tipeSo, setTipeSo] = React.useState("");
  const [totalAllHarga, setTotalAllHarga] = React.useState(0);
  const [dokumen, setDokumen] = React.useState([]);
  const [instansiCustomerValidasi, setInstansiCustomerValidasi] =
    React.useState(false);
  const [namaCustomerValidasi, setNamaCustomerValidasi] = React.useState(false);
  const [alamatCustomerValidasi, setAlamatCustomerValidasi] =
    React.useState(false);
  const [notelpCustomerValidasi, setNotelpCustomerValidasi] =
    React.useState(false);
  const [emailCustomerValidasi, setEmailCustomerValidasi] =
    React.useState(false);
  const [tipeSoValidasi, setTipeSoValidasi] = React.useState(false);
  const [dokumenValidasi, setDokumenValidasi] = React.useState(false);
  const [keranjangValidasi, setKeranjangValidasi] = React.useState(false);
  const [keranjangValidasiUpgrade, setKeranjangValidasiUpgrade] =
    React.useState(false);
  const [formatEmail, setFormatEmail] = React.useState(true);
  const [formatPhone, setFormatPhone] = React.useState(false);
  const notificationAlertRef = React.useRef(null);

  const [namaPenerima, setNamaPenerima] = React.useState("");
  const [alamatPenerima, setAlamatPenerima] = React.useState("");
  const [notelpPenerima, setNotelpPenerima] = React.useState("");
  const [namaPenerimaValidasi, setNamaPenerimaValidasi] = React.useState(false);
  const [alamatPenerimaValidasi, setAlamatPenerimaValidasi] =
    React.useState(false);
  const [notelpPenerimaValidasi, setNotelpPenerimaValidasi] =
    React.useState(false);
  const [formatPhonePenerima, setFormatPhonePenerima] = React.useState(false);

  const [modalImage, setModalImage] = React.useState(false);
  const [urlImage, setUrlImage] = React.useState("");
  const [pending, setPending] = React.useState(false);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(12);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [totalPerHalamanUpgrade, setTotalPerHalamanUpgrade] =
    React.useState(12);
  const [pageUpgrade, setPageUpgrade] = React.useState(0);
  const [searchTextUpgrade, setSearchTextUpgrade] = React.useState("");

  function handleFileChange(e) {
    // const selectedFiles = Array.from(e.target.files);
    setDokumen([...dokumen, ...Array.from(e.target.files)]);
    setDokumenValidasi(false);
  }

  const removeDokumen = (index) => {
    setDokumen(dokumen.filter((_, i) => i !== index));
  };

  function formatCurrency(amount) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(amount);
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchUpgrade = (e) => {
    setSearchTextUpgrade(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleClearSearchUpgrade = () => {
    setSearchTextUpgrade("");
  };

  const options = [
    { value: 12, label: "12" },
    { value: 60, label: "60" },
    { value: 100, label: "100" },
  ];

  const optionsUpgrade = [
    { value: 12, label: "12" },
    { value: 60, label: "60" },
    { value: 100, label: "100" },
  ];

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handlePageSizeChangeUpgrade = (selectedOption) => {
    setTotalPerHalamanUpgrade(selectedOption.value);
  };

  React.useEffect(() => {
    let tmp =
      produk.listProduk.data &&
      produk.listProduk.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListProduk(tmp);
    if (produk.listProduk.length != 0) {
      setPending(false);
    }
  }, [produk.listProduk]);

  React.useEffect(() => {
    let tmp =
      produk.listProdukUpgrade.data &&
      produk.listProdukUpgrade.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListProdukUpgrade(tmp);
    if (produk.listProdukUpgrade.length != 0) {
      setPending(false);
    }
  }, [produk.listProdukUpgrade]);

  React.useEffect(() => {
    getProduk(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    });
  }, [page, totalPerHalaman, searchText]);

  React.useEffect(() => {
    getProdukUpgrade(dispatch, {
      cari: searchTextUpgrade,
      page: pageUpgrade + 1,
      limit: totalPerHalamanUpgrade,
    });
  }, [pageUpgrade, totalPerHalamanUpgrade, searchTextUpgrade]);

  // const tambahKeKeranjang = (data) => {
  //   const produk = listProduk.find((p) => p.idProduk === data.idProduk);
  //   if (data.sumberProduk === "SG") {
  //     if (produk) {
  //       setKeranjang((prevKeranjang) => {
  //         const existingProduct = prevKeranjang.find(
  //           (p) => p.idProduk === data.idProduk
  //         );
  //         if (existingProduct) {
  //           if (existingProduct.qty < produk.stockSales) {
  //             notify({
  //               data: produk,
  //               code: 0,
  //               message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //             });
  //             return prevKeranjang.map((p) =>
  //               p.idProduk === data.idProduk
  //                 ? {
  //                     ...p,
  //                     qty: p.qty + 1,
  //                     totalHarga: p.totalHarga + produk.hargaProduk,
  //                     sumberProduk: "SG",
  //                   }
  //                 : p
  //             );
  //           } else {
  //             notify({
  //               data: produk,
  //               code: 1,
  //               message: `Jumlah produk sudah mencapai stock maksimal`,
  //             });
  //             return prevKeranjang;
  //           }
  //         } else {
  //           if (produk.stockSales > 0) {
  //             notify({
  //               data: produk,
  //               code: 0,
  //               message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //             });
  //             return [
  //               ...prevKeranjang,
  //               {
  //                 ...produk,
  //                 qty: 1,
  //                 totalHarga: produk.hargaProduk,
  //                 sumberProduk: "SG",
  //               },
  //             ];
  //           } else {
  //             notify({ data: produk, code: 1, message: `Stock produk habis` });
  //             return prevKeranjang;
  //           }
  //         }
  //       });
  //     }
  //   } else {
  //     if (produk) {
  //       setKeranjang((prevKeranjang) => {
  //         const existingProduct = prevKeranjang.find(
  //           (p) => p.idProduk === data.idProduk
  //         );
  //         if (existingProduct) {
  //           notify({
  //             data: produk,
  //             code: 0,
  //             message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //           });
  //           return prevKeranjang.map((p) =>
  //             p.idProduk === data.idProduk
  //               ? {
  //                   ...p,
  //                   qty: p.qty + 1,
  //                   totalHarga: p.totalHarga + produk.hargaProduk,
  //                   sumberProduk: "PL",
  //                 }
  //               : p
  //           );
  //         } else {
  //           notify({
  //             data: produk,
  //             code: 0,
  //             message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //           });
  //           return [
  //             ...prevKeranjang,
  //             {
  //               ...produk,
  //               qty: 1,
  //               totalHarga: produk.hargaProduk,
  //               sumberProduk: "PL",
  //             },
  //           ];
  //         }
  //       });
  //     }
  //   }
  // };

  const tambahKeKeranjang = (data) => {
    const produk = listProduk.find((p) => p.idProduk === data.idProduk);
    if (produk) {
      setKeranjang((prevKeranjang) => {
        let totalQty = prevKeranjang.reduce((acc, p) => {
          if (p.idProduk === data.idProduk) {
            return acc + p.qty;
          }
          return acc;
        }, 0);

        const isSG = totalQty < produk.stockSales;

        if (isSG) {
          const existingProductSG = prevKeranjang.find(
            (p) => p.idProduk === data.idProduk && p.sumberProduk === "SG"
          );

          if (existingProductSG) {
            if (existingProductSG.qty < produk.stockSales) {
              notify({
                data: produk,
                code: 0,
                message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
              });
              return prevKeranjang.map((p) =>
                p.idProduk === data.idProduk && p.sumberProduk === "SG"
                  ? {
                      ...p,
                      qty: p.qty + 1,
                      totalHarga: p.totalHarga + produk.hargaProduk,
                    }
                  : p
              );
            } else {
              notify({
                data: produk,
                code: 1,
                message: `Jumlah produk sudah mencapai stock maksimal`,
              });
              return prevKeranjang;
            }
          } else {
            if (produk.stockSales > 0) {
              notify({
                data: produk,
                code: 0,
                message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
              });
              return [
                ...prevKeranjang,
                {
                  ...produk,
                  qty: 1,
                  totalHarga: produk.hargaProduk,
                  sumberProduk: "SG",
                },
              ];
            } else {
              notify({ data: produk, code: 1, message: `Stock produk habis` });
              return prevKeranjang;
            }
          }
        } else {
          const existingProductPL = prevKeranjang.find(
            (p) => p.idProduk === data.idProduk && p.sumberProduk === "PL"
          );

          if (existingProductPL) {
            notify({
              data: produk,
              code: 0,
              message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
            });
            return prevKeranjang.map((p) =>
              p.idProduk === data.idProduk && p.sumberProduk === "PL"
                ? {
                    ...p,
                    qty: p.qty + 1,
                    totalHarga: p.totalHarga + produk.hargaProduk,
                  }
                : p
            );
          } else {
            notify({
              data: produk,
              code: 0,
              message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
            });
            return [
              ...prevKeranjang,
              {
                ...produk,
                qty: 1,
                totalHarga: produk.hargaProduk,
                sumberProduk: "PL",
              },
            ];
          }
        }
      });
    }
  };

  const tambahKeKeranjangUpgrade = (data) => {
    const produk = listProdukUpgrade.find((p) => p.idProduk === data.idProduk);
    if (produk) {
      setKeranjangUpgrade((prevKeranjang) => {
        let totalQty = prevKeranjang.reduce((acc, p) => {
          if (p.idProduk === data.idProduk) {
            return acc + p.qty;
          }
          return acc;
        }, 0);

        const remainingStock = produk.stockSales - totalQty;
        const isSG = remainingStock > 0;

        if (isSG) {
          const existingProductSG = prevKeranjang.find(
            (p) => p.idProduk === data.idProduk && p.sumberProduk === "SG"
          );

          if (existingProductSG) {
            if (existingProductSG.qty < produk.stockSales) {
              notify({
                data: produk,
                code: 0,
                message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
              });
              return prevKeranjang.map((p) =>
                p.idProduk === data.idProduk && p.sumberProduk === "SG"
                  ? {
                      ...p,
                      qty: p.qty + 1,
                      totalHarga: p.totalHarga + produk.hargaProduk,
                    }
                  : p
              );
            } else {
              notify({
                data: produk,
                code: 1,
                message: `Jumlah produk sudah mencapai stock maksimal`,
              });
              return prevKeranjang;
            }
          } else {
            if (produk.stockSales > 0) {
              notify({
                data: produk,
                code: 0,
                message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
              });
              return [
                ...prevKeranjang,
                {
                  ...produk,
                  qty: 1,
                  totalHarga: produk.hargaProduk,
                  sumberProduk: "SG",
                },
              ];
            } else {
              notify({ data: produk, code: 1, message: `Stock produk habis` });
              return prevKeranjang;
            }
          }
        } else {
          const existingProductPL = prevKeranjang.find(
            (p) => p.idProduk === data.idProduk && p.sumberProduk === "PL"
          );

          if (existingProductPL) {
            notify({
              data: produk,
              code: 0,
              message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
            });
            return prevKeranjang.map((p) =>
              p.idProduk === data.idProduk && p.sumberProduk === "PL"
                ? {
                    ...p,
                    qty: p.qty + 1,
                    totalHarga: p.totalHarga + produk.hargaProduk,
                  }
                : p
            );
          } else {
            notify({
              data: produk,
              code: 0,
              message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
            });
            return [
              ...prevKeranjang,
              {
                ...produk,
                qty: 1,
                totalHarga: produk.hargaProduk,
                sumberProduk: "PL",
              },
            ];
          }
        }
      });
    }
  };

  // const tambahKeKeranjangUpgrade = (data) => {
  //   const produk = listProdukUpgrade.find((p) => p.idProduk === data.idProduk);
  //   if (data.sumberProduk === "SG") {
  //     if (produk) {
  //       setKeranjangUpgrade((prevKeranjang) => {
  //         const existingProduct = prevKeranjang.find(
  //           (p) => p.idProduk === data.idProduk
  //         );
  //         if (existingProduct) {
  //           if (existingProduct.qty < produk.stockSales) {
  //             notify({
  //               data: produk,
  //               code: 0,
  //               message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //             });
  //             return prevKeranjang.map((p) =>
  //               p.idProduk === data.idProduk
  //                 ? {
  //                     ...p,
  //                     qty: p.qty + 1,
  //                     totalHarga: p.totalHarga + produk.hargaProduk,
  //                     sumberProduk: "SG",
  //                   }
  //                 : p
  //             );
  //           } else {
  //             notify({
  //               data: produk,
  //               code: 1,
  //               message: `Jumlah produk sudah mencapai stock maksimal`,
  //             });
  //             return prevKeranjang;
  //           }
  //         } else {
  //           if (produk.stockSales > 0) {
  //             notify({
  //               data: produk,
  //               code: 0,
  //               message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //             });
  //             return [
  //               ...prevKeranjang,
  //               {
  //                 ...produk,
  //                 qty: 1,
  //                 totalHarga: produk.hargaProduk,
  //                 sumberProduk: "SG",
  //               },
  //             ];
  //           } else {
  //             notify({ data: produk, code: 1, message: `Stock produk habis` });
  //             return prevKeranjang;
  //           }
  //         }
  //       });
  //     }
  //   } else {
  //     if (produk) {
  //       setKeranjangUpgrade((prevKeranjang) => {
  //         const existingProduct = prevKeranjang.find(
  //           (p) => p.idProduk === data.idProduk
  //         );
  //         if (existingProduct) {
  //           notify({
  //             data: produk,
  //             code: 0,
  //             message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //           });
  //           return prevKeranjang.map((p) =>
  //             p.idProduk === data.idProduk
  //               ? {
  //                   ...p,
  //                   qty: p.qty + 1,
  //                   totalHarga: p.totalHarga + produk.hargaProduk,
  //                   sumberProduk: "PL",
  //                 }
  //               : p
  //           );
  //         } else {
  //           notify({
  //             data: produk,
  //             code: 0,
  //             message: `Produk ${produk.kodeProduk} berhasil ditambahkan ke keranjang`,
  //           });
  //           return [
  //             ...prevKeranjang,
  //             {
  //               ...produk,
  //               qty: 1,
  //               totalHarga: produk.hargaProduk,
  //               sumberProduk: "PL",
  //             },
  //           ];
  //         }
  //       });
  //     }
  //   }
  // };

  // const hapusDariKeranjang = (idProduk) => {
  //   setKeranjang((prevKeranjang) => {
  //     const existingProduct = prevKeranjang.find(
  //       (p) => p.idProduk === idProduk
  //     );
  //     if (existingProduct.qty === 1) {
  //       return prevKeranjang.filter((p) => p.idProduk !== idProduk);
  //     } else {
  //       return prevKeranjang.map((p) =>
  //         p.idProduk === idProduk
  //           ? { ...p, qty: p.qty - 1, totalHarga: p.totalHarga - p.hargaProduk }
  //           : p
  //       );
  //     }
  //   });
  // };

  const hapusDariKeranjang = (idProduk) => {
    setKeranjang((prevKeranjang) => {
      // Cari produk dengan sumberProduk "PL" terlebih dahulu
      const existingProductPL = prevKeranjang.find(
        (p) => p.idProduk === idProduk && p.sumberProduk === "PL"
      );

      if (existingProductPL) {
        if (existingProductPL.qty === 1) {
          return prevKeranjang.filter(
            (p) => !(p.idProduk === idProduk && p.sumberProduk === "PL")
          );
        } else {
          return prevKeranjang.map((p) =>
            p.idProduk === idProduk && p.sumberProduk === "PL"
              ? {
                  ...p,
                  qty: p.qty - 1,
                  totalHarga: p.totalHarga - p.hargaProduk,
                }
              : p
          );
        }
      }

      // Jika tidak ada produk dengan sumberProduk "PL", cari produk dengan sumberProduk "SG"
      const existingProductSG = prevKeranjang.find(
        (p) => p.idProduk === idProduk && p.sumberProduk === "SG"
      );

      if (existingProductSG) {
        if (existingProductSG.qty === 1) {
          return prevKeranjang.filter(
            (p) => !(p.idProduk === idProduk && p.sumberProduk === "SG")
          );
        } else {
          return prevKeranjang.map((p) =>
            p.idProduk === idProduk && p.sumberProduk === "SG"
              ? {
                  ...p,
                  qty: p.qty - 1,
                  totalHarga: p.totalHarga - p.hargaProduk,
                }
              : p
          );
        }
      }

      return prevKeranjang;
    });
  };

  const hapusDariKeranjangUpgrade = (idProduk) => {
    setKeranjangUpgrade((prevKeranjang) => {
      // Cari produk dengan sumberProduk "PL" terlebih dahulu
      const existingProductPL = prevKeranjang.find(
        (p) => p.idProduk === idProduk && p.sumberProduk === "PL"
      );

      if (existingProductPL) {
        if (existingProductPL.qty === 1) {
          return prevKeranjang.filter(
            (p) => !(p.idProduk === idProduk && p.sumberProduk === "PL")
          );
        } else {
          return prevKeranjang.map((p) =>
            p.idProduk === idProduk && p.sumberProduk === "PL"
              ? {
                  ...p,
                  qty: p.qty - 1,
                  totalHarga: p.totalHarga - p.hargaProduk,
                }
              : p
          );
        }
      }

      // Jika tidak ada produk dengan sumberProduk "PL", cari produk dengan sumberProduk "SG"
      const existingProductSG = prevKeranjang.find(
        (p) => p.idProduk === idProduk && p.sumberProduk === "SG"
      );

      if (existingProductSG) {
        if (existingProductSG.qty === 1) {
          return prevKeranjang.filter(
            (p) => !(p.idProduk === idProduk && p.sumberProduk === "SG")
          );
        } else {
          return prevKeranjang.map((p) =>
            p.idProduk === idProduk && p.sumberProduk === "SG"
              ? {
                  ...p,
                  qty: p.qty - 1,
                  totalHarga: p.totalHarga - p.hargaProduk,
                }
              : p
          );
        }
      }

      return prevKeranjang;
    });
  };

  // const hapusDariKeranjangUpgrade = (idProduk) => {
  //   setKeranjangUpgrade((prevKeranjang) => {
  //     const existingProduct = prevKeranjang.find(
  //       (p) => p.idProduk === idProduk
  //     );
  //     if (existingProduct.qty === 1) {
  //       return prevKeranjang.filter((p) => p.idProduk !== idProduk);
  //     } else {
  //       return prevKeranjang.map((p) =>
  //         p.idProduk === idProduk
  //           ? { ...p, qty: p.qty - 1, totalHarga: p.totalHarga - p.hargaProduk }
  //           : p
  //       );
  //     }
  //   });
  // };

  // const hapusItemDariKeranjang = (idProduk) => {
  //   setKeranjang((prevKeranjang) =>
  //     prevKeranjang.filter((p) => p.idProduk !== idProduk)
  //   );
  // };
  const hapusItemDariKeranjang = (idProduk) => {
    setKeranjang((prevKeranjang) => {
      // Cari produk dengan sumberProduk "PL" terlebih dahulu
      const existingProductPL = prevKeranjang.find(
        (p) => p.idProduk === idProduk && p.sumberProduk === "PL"
      );

      if (existingProductPL) {
        return prevKeranjang.filter(
          (p) => !(p.idProduk === idProduk && p.sumberProduk === "PL")
        );
      }

      // Jika tidak ada produk dengan sumberProduk "PL", cari produk dengan sumberProduk "SG"
      return prevKeranjang.filter(
        (p) => !(p.idProduk === idProduk && p.sumberProduk === "SG")
      );
    });
  };

  const hapusItemDariKeranjangUpgrade = (idProduk) => {
    setKeranjangUpgrade((prevKeranjang) => {
      // Cari produk dengan sumberProduk "PL" terlebih dahulu
      const existingProductPL = prevKeranjang.find(
        (p) => p.idProduk === idProduk && p.sumberProduk === "PL"
      );

      if (existingProductPL) {
        return prevKeranjang.filter(
          (p) => !(p.idProduk === idProduk && p.sumberProduk === "PL")
        );
      }

      // Jika tidak ada produk dengan sumberProduk "PL", cari produk dengan sumberProduk "SG"
      return prevKeranjang.filter(
        (p) => !(p.idProduk === idProduk && p.sumberProduk === "SG")
      );
    });
  };

  // const hapusItemDariKeranjangUpgrade = (idProduk) => {
  //   setKeranjangUpgrade((prevKeranjang) =>
  //     prevKeranjang.filter((p) => p.idProduk !== idProduk)
  //   );
  // };

  React.useEffect(() => {
    const total = keranjang.reduce((acc, produk) => acc + produk.totalHarga, 0);
    const total2 = keranjangUpgrade.reduce(
      (acc, produk) => acc + produk.totalHarga,
      0
    );
    setTotalAllHarga(total + total2);
  }, [keranjang, keranjangUpgrade]);

  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const emailValidation = (value) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const kirim = (e) => {
    e.preventDefault();

    if (instansiCustomer === "") {
      setInstansiCustomerValidasi(true);
    }
    if (namaCustomer === "") {
      setNamaCustomerValidasi(true);
    }
    if (alamatCustomer === "") {
      setAlamatCustomerValidasi(true);
    }
    if (notelpCustomer === "") {
      setNotelpCustomerValidasi(true);
    }

    if (tipeSo === "") {
      setTipeSoValidasi(true);
    }

    if (dokumen.length === 0) {
      setDokumenValidasi(true);
    }
    // if (namaPenerima === "") {
    //   setNamaPenerimaValidasi(true);
    // }
    // if (alamatPenerima === "") {
    //   setAlamatPenerimaValidasi(true);
    // }
    // if (notelpPenerima === "") {
    //   setNotelpPenerimaValidasi(true);
    // }
    if (
      instansiCustomer === "" ||
      namaCustomer === "" ||
      alamatCustomer === "" ||
      notelpCustomer === "" ||
      tipeSo === "" ||
      dokumen.length === 0 ||
      formatEmail === false ||
      formatPhone === false ||
      (keranjang.length === 0 && keranjangUpgrade.length === 0)
    ) {
      notify({
        code: 1,
        message:
          "Periksa kembali isian anda jangan ada yang salah atau kosong atau keranjang anda masih kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk melakukan order?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Order!",
      }).then((result) => {
        let joinKeranjang = keranjang.concat(keranjangUpgrade);

        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Order sedang dikirim,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          let formData = new FormData();
          formData.append("customerInstansi", instansiCustomer);
          formData.append("namaCustomer", namaCustomer);
          formData.append("alamatCustomer", alamatCustomer);
          formData.append("noTelp", notelpCustomer);
          formData.append("email", emailCustomer);
          formData.append("tipeSo", tipeSo.value);
          formData.append("namaPenerima", namaPenerima);
          formData.append("alamatPenerima", alamatPenerima);
          formData.append("noTelpPenerima", notelpPenerima);
          formData.append(
            "tglOrder",
            moments.tz(new Date(), "Asia/Jakarta").format("YYYY-MM-DD")
          );
          formData.append("totalPembelian", totalAllHarga);
          formData.append("listProduk", JSON.stringify(joinKeranjang));
          dokumen.map((val, index) => {
            formData.append("dokumen", val);
          });
          orderProduk(formData).then((response) => {
            if (response.data.status === 200) {
              setPending(false);
              setShowKeranjang(false);
              setKeranjang([]);
              setKeranjangUpgrade([]);
              setInstansiCustomer("");
              setNamaCustomer("");
              setAlamatCustomer("");
              setNotelpCustomer("");
              setEmailCustomer("");
              setTipeSo("");
              setTotalAllHarga(0);
              setDokumen([]);
              setInstansiCustomerValidasi(false);
              setNamaCustomerValidasi(false);
              setAlamatCustomerValidasi(false);
              setNotelpCustomerValidasi(false);
              setEmailCustomerValidasi(false);
              setTipeSoValidasi(false);
              setDokumenValidasi(false);
              setKeranjangValidasi(false);
              setKeranjangValidasiUpgrade(false);
              setFormatEmail(true);
              setFormatPhone(false);
              setNamaPenerima("");
              setAlamatPenerima("");
              setNotelpPenerima("");
              getProduk(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
              });
              getProdukUpgrade(dispatch, {
                cari: searchTextUpgrade,
                page: pageUpgrade + 1,
                limit: totalPerHalamanUpgrade,
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal menambahkan Produk"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {showKeranjang ? (
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Col>
              <div style={{ fontSize: 25, fontWeight: "bold" }}>Keranjang</div>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(255,1,0,1)",
                  borderColor: "rgba(255,1,0,1)",
                }}
                onClick={() => {
                  setShowKeranjang(false);
                }}
              >
                <i className="fas fa-times" style={{ marginRight: 10 }}></i>
                Close
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              {keranjang.length != 0 || keranjangUpgrade.length != 0 ? (
                <>
                  <div style={{ fontSize: 20 }}>
                    <b>Produk Utama</b>
                  </div>

                  {keranjang.length === 0 ? (
                    <div style={{ color: "grey" }}>Tanpa Produk Utama</div>
                  ) : (
                    keranjang.map((val) => {
                      return (
                        <Card>
                          <Card.Body>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <img
                                  alt="..."
                                  src={
                                    val.fotoProduk.length === 0
                                      ? require("assets/img/noImage.jpg")
                                          .default
                                      : val.fotoProduk[0]
                                  }
                                  style={{
                                    objectFit: "cover",

                                    width: "100px",
                                    height: "100px",
                                  }}
                                ></img>
                              </div>
                              <div
                                style={{
                                  padding: "10px 15px 10px 15px",
                                  flex: 2,
                                }}
                              >
                                <div
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  {val.kodeProduk} | {val.tipe}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  {val.namaProduk} | {val.kapasitas}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  <b>{formatCurrency(val.hargaProduk)}</b>
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  {val.stockSales} Stock
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  <b>
                                    {val.sumberProduk === "SG"
                                      ? "Gudang"
                                      : "Pesan Langsung"}
                                  </b>
                                </div>
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  textAlign: "end",
                                }}
                              >
                                <div style={{ fontSize: 18 }}>
                                  <b>{formatCurrency(val.totalHarga)}</b>
                                </div>
                                <div>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      hapusDariKeranjang(
                                        val.idProduk,
                                        val.sumberProduk
                                      );
                                    }}
                                  >
                                    <b>-</b>
                                  </Button>
                                  <label
                                    style={{
                                      fontSize: 18,
                                      padding: 10,
                                      color: "#000000",
                                    }}
                                  >
                                    <b>{val.qty}</b>
                                  </label>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      val.sumberProduk === "SG"
                                        ? tambahKeKeranjang({
                                            idProduk: val.idProduk,
                                            sumberProduk: "SG",
                                          })
                                        : tambahKeKeranjang({
                                            idProduk: val.idProduk,
                                            sumberProduk: "PL",
                                          });
                                    }}
                                  >
                                    <b>+</b>
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    variant="danger"
                                    onClick={() => {
                                      hapusItemDariKeranjang(
                                        val.idProduk,
                                        val.sumberProduk
                                      );
                                    }}
                                  >
                                    <i className="fa fa-trash" /> Hapus
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })
                  )}
                  <hr style={{ borderWidth: 5 }}></hr>
                  <div style={{ fontSize: 20 }}>
                    <b>Upgrade</b>
                  </div>
                  {keranjangUpgrade.length === 0 ? (
                    <div style={{ color: "grey" }}>Tanpa Upgrade</div>
                  ) : (
                    keranjangUpgrade.map((val) => {
                      return (
                        <Card>
                          <Card.Body>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <img
                                  alt="..."
                                  src={
                                    val.fotoProduk.length === 0
                                      ? require("assets/img/noImage.jpg")
                                          .default
                                      : val.fotoProduk[0]
                                  }
                                  style={{
                                    objectFit: "cover",

                                    width: "100px",
                                    height: "100px",
                                  }}
                                ></img>
                              </div>
                              <div
                                style={{
                                  padding: "10px 15px 10px 15px",
                                  flex: 2,
                                }}
                              >
                                <div
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  {val.kodeProduk} | {val.tipe}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  {val.namaProduk} | {val.kapasitas}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  <b>{formatCurrency(val.hargaProduk)}</b>
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  {val.stockSales} Stock
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  <b>
                                    {val.sumberProduk === "SG"
                                      ? "Gudang"
                                      : "Pesan Langsung"}
                                  </b>
                                </div>
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  textAlign: "end",
                                }}
                              >
                                <div style={{ fontSize: 18 }}>
                                  <b>{formatCurrency(val.totalHarga)}</b>
                                </div>
                                <div>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      hapusDariKeranjangUpgrade(
                                        val.idProduk,
                                        val.sumberProduk
                                      );
                                    }}
                                  >
                                    <b>-</b>
                                  </Button>
                                  <label
                                    style={{
                                      fontSize: 18,
                                      padding: 10,
                                      color: "#000000",
                                    }}
                                  >
                                    <b>{val.qty}</b>
                                  </label>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      val.sumberProduk === "SG"
                                        ? tambahKeKeranjangUpgrade({
                                            idProduk: val.idProduk,
                                            sumberProduk: "SG",
                                          })
                                        : tambahKeKeranjangUpgrade({
                                            idProduk: val.idProduk,
                                            sumberProduk: "PL",
                                          });
                                    }}
                                  >
                                    <b>+</b>
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    variant="danger"
                                    onClick={() => {
                                      hapusItemDariKeranjangUpgrade(
                                        val.idProduk,
                                        val.sumberProduk
                                      );
                                    }}
                                  >
                                    <i className="fa fa-trash" /> Hapus
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })
                  )}
                </>
              ) : (
                <div>
                  <b style={{ color: "grey" }}>Keranjang Anda Masih Kosong</b>
                </div>
              )}
            </Col>
            <Col md="6">
              <Card>
                <Card.Body>
                  <div>
                    <div style={{ fontSize: 30 }}>
                      Total :{" "}
                      <b style={{ fontSize: 35 }}>
                        {formatCurrency(totalAllHarga)}
                      </b>
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <div>
                      <b style={{ fontSize: 20 }}>Informasi Customer</b>
                    </div>
                    <div style={{ marginTop: 15 }}>
                      <Form action="#" method="#">
                        <Form.Group>
                          <label style={{ color: "black", fontWeight: "bold" }}>
                            Instansi Customer <span className="star">*</span>
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setInstansiCustomer(e.target.value);
                              setInstansiCustomerValidasi(false);
                            }}
                            placeholder="Instansi"
                            type="text"
                            value={instansiCustomer}
                          ></Form.Control>
                          {instansiCustomerValidasi === false ? null : (
                            <label
                              className="error"
                              style={{ textTransform: "none", color: "red" }}
                            >
                              Instansi customer tidak boleh kosong.
                            </label>
                          )}
                        </Form.Group>
                        <Form.Group>
                          <label style={{ color: "black", fontWeight: "bold" }}>
                            Nama Customer <span className="star">*</span>
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setNamaCustomer(e.target.value);
                              setNamaCustomerValidasi(false);
                            }}
                            placeholder="Nama"
                            type="text"
                            value={namaCustomer}
                          ></Form.Control>
                          {namaCustomerValidasi === false ? null : (
                            <label
                              className="error"
                              style={{ textTransform: "none", color: "red" }}
                            >
                              Nama customer tidak boleh kosong.
                            </label>
                          )}
                        </Form.Group>
                        <Form.Group>
                          <label style={{ color: "black", fontWeight: "bold" }}>
                            Alamat Customer <span className="star">*</span>
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setAlamatCustomer(e.target.value);
                              setAlamatCustomerValidasi(false);
                            }}
                            placeholder="Alamat"
                            type="text"
                            value={alamatCustomer}
                          ></Form.Control>
                          {alamatCustomerValidasi === false ? null : (
                            <label
                              className="error"
                              style={{ textTransform: "none", color: "red" }}
                            >
                              Alamat customer tidak boleh kosong.
                            </label>
                          )}
                        </Form.Group>
                        <Form.Group>
                          <label style={{ color: "black", fontWeight: "bold" }}>
                            No Telp Customer <span className="star">*</span>
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setNotelpCustomer(e.target.value);
                              setNotelpCustomerValidasi(false);

                              setFormatPhone(
                                validatePhoneNumber(e.target.value)
                              );
                            }}
                            placeholder="No Telp"
                            type="text"
                            maxLength={14}
                            value={notelpCustomer}
                          ></Form.Control>
                          {formatPhone === true ||
                          notelpCustomer === "" ? null : (
                            <label
                              className="error"
                              style={{ textTransform: "none", color: "red" }}
                            >
                              Format telpn salah atau terlalu panjang (maksimal
                              14 digit).
                            </label>
                          )}
                          {notelpCustomerValidasi === false ? null : (
                            <label
                              className="error"
                              style={{ textTransform: "none", color: "red" }}
                            >
                              No Telpn customer tidak boleh kosong.
                            </label>
                          )}
                        </Form.Group>
                        <Form.Group>
                          <label style={{ color: "black", fontWeight: "bold" }}>
                            Tipe Order <span className="star">*</span>
                          </label>

                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={tipeSo}
                            onChange={(value) => {
                              setTipeSo(value);
                              setTipeSoValidasi(false);
                            }}
                            options={[
                              { value: "Reguler", label: "Reguler" },
                              { value: "Online", label: "Onlie" },
                            ]}
                            placeholder="Pilih Tipe Order"
                          />
                        </Form.Group>
                        {tipeSoValidasi === false ? null : (
                          <label
                            className="error"
                            style={{ textTransform: "none", color: "red" }}
                          >
                            Tipe order tidak boleh kosong.
                          </label>
                        )}
                        <Form.Group>
                          <label style={{ color: "black", fontWeight: "bold" }}>
                            Email Customer
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setEmailCustomer(e.target.value);
                              if (e.target.value === "") {
                                setFormatEmail(true);
                              } else {
                                setFormatEmail(emailValidation(e.target.value));
                              }
                            }}
                            placeholder="Email"
                            type="text"
                            value={emailCustomer}
                          ></Form.Control>
                          {formatEmail === true ||
                          emailCustomer === "" ? null : (
                            <label
                              className="error"
                              style={{ textTransform: "none", color: "red" }}
                            >
                              Format Email salah.
                            </label>
                          )}
                        </Form.Group>
                        <hr style={{ borderWidth: 3 }}></hr>
                        <div>
                          <b style={{ fontSize: 20 }}>Informasi Penerima</b>
                        </div>
                        <div style={{ marginTop: 15 }}>
                          <Form.Group>
                            <label
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Nama Penerima
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setNamaPenerima(e.target.value);
                                // setNamaPenerimaValidasi(false);
                              }}
                              placeholder="Nama"
                              type="text"
                              value={namaPenerima}
                            ></Form.Control>
                            {/* {namaPenerimaValidasi === false ? null : (
                              <label
                                className="error"
                                style={{
                                  textTransform: "none",
                                  color: "red",
                                }}
                              >
                                Nama penerima tidak boleh kosong.
                              </label>
                            )} */}
                          </Form.Group>
                          <Form.Group>
                            <label
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Alamat Penerima
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setAlamatPenerima(e.target.value);
                                // setAlamatPenerimaValidasi(false);
                              }}
                              placeholder="Alamat"
                              type="text"
                              value={alamatPenerima}
                            ></Form.Control>
                            {/* {alamatPenerimaValidasi === false ? null : (
                              <label
                                className="error"
                                style={{
                                  textTransform: "none",
                                  color: "red",
                                }}
                              >
                                Alamat penerima tidak boleh kosong.
                              </label>
                            )} */}
                          </Form.Group>
                          <Form.Group>
                            <label
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              No Telp Penerima
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setNotelpPenerima(e.target.value);
                                // setNotelpPenerimaValidasi(false);

                                setFormatPhonePenerima(
                                  validatePhoneNumber(e.target.value)
                                );
                              }}
                              placeholder="No Telp"
                              type="text"
                              maxLength={14}
                              value={notelpPenerima}
                            ></Form.Control>
                            {formatPhonePenerima === true ||
                            notelpPenerima === "" ? null : (
                              <label
                                className="error"
                                style={{
                                  textTransform: "none",
                                  color: "red",
                                }}
                              >
                                Format telpn salah atau terlalu panjang
                                (maksimal 14 digit).
                              </label>
                            )}
                            {/* {notelpPenerimaValidasi === false ? null : (
                              <label
                                className="error"
                                style={{
                                  textTransform: "none",
                                  color: "red",
                                }}
                              >
                                No Telpn penerima tidak boleh kosong.
                              </label>
                            )} */}
                          </Form.Group>
                        </div>

                        <div>
                          <div>
                            <label
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Upload Dokumen{" "}
                              <span
                                className="star"
                                style={{ textTransform: "none" }}
                              >
                                *{" "}
                                <span style={{ color: "blue" }}>
                                  (Maksimal 2 Dokumen)
                                </span>
                              </span>
                            </label>
                          </div>

                          <label
                            className="label-input-file btn text-white mb-4"
                            htmlFor="import2"
                            style={{
                              fontWeight: "bold",
                              backgroundColor:
                                dokumen.length === 2 ? "grey" : "#447DF7",
                              border: 0,
                              marginTop: 10,
                            }}
                          >
                            <div
                              style={{
                                color: "#FFFFFF",
                                textTransform: "none",
                              }}
                            >
                              <i
                                className="fas fa-images"
                                style={{ marginRight: 5 }}
                              ></i>
                              Pilih File
                            </div>

                            <input
                              disabled={
                                pending || dokumen.length === 2 ? true : false
                              }
                              type="file"
                              id="import2"
                              onClick={(e) => {
                                e.target.value = "";
                              }}
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              accept=".jpg,.jpeg,.png"
                              multiple
                            />
                          </label>
                          {dokumenValidasi === false ? null : (
                            <label
                              className="error"
                              style={{
                                textTransform: "none",
                                color: "red",
                                marginLeft: 10,
                              }}
                            >
                              Dokumen tidak boleh kosong.
                            </label>
                          )}
                          {dokumen.length === 0 ? (
                            <div>
                              <p style={{ color: "GrayText" }}>File kosong</p>
                            </div>
                          ) : (
                            dokumen.map((file, index) => (
                              <div
                                className="d-flex align-items-center"
                                key={index}
                              >
                                <p className="mb-1 mr-1">{file.name}</p>

                                <i
                                  className="fa fa-times"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    marginLeft: 10,
                                  }}
                                  onClick={() =>
                                    pending ? null : removeDokumen(index)
                                  }
                                />
                              </div>
                            ))
                          )}
                        </div>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer style={{ textAlign: "end" }}>
                  <Button onClick={kirim}>
                    <i class="fas fa-paper-plane"></i> Order
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container fluid>
            <Row
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Col>
                <div style={{ fontSize: 25, fontWeight: "bold" }}>Produk</div>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button
                  style={{
                    marginLeft: 20,
                    marginBottom: 0,
                    backgroundColor: "rgba(40,118,74,1)",
                    borderColor: "rgba(40,118,74,1)",
                  }}
                  onClick={() => {
                    keranjang.length != 0 || keranjangUpgrade.length != 0
                      ? setShowKeranjang(true)
                      : notify({
                          code: 1,
                          message:
                            "Maaf anda belum memiliki produk pada keranjang belanja",
                        });
                  }}
                >
                  <i
                    className="fas fa-dolly-flatbed"
                    style={{ marginRight: 10 }}
                  ></i>
                  Keranjang
                  <Badge
                    style={{
                      backgroundColor: "red",
                      fontSize: 12,
                      borderRadius: 10,
                      width: 20,
                      height: 20,
                      position: "absolute",
                      right: 5,
                      top: -10,
                    }}
                  >
                    {keranjang.length + keranjangUpgrade.length}
                  </Badge>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <InputGroup>
                      <Form.Control
                        style={{
                          paddingLeft: "10px",
                          width: "300px",
                        }}
                        type="text"
                        value={searchText}
                        onChange={handleSearch}
                        placeholder="Nama Mesin/Kode Mesin"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleClearSearch}
                        >
                          <i class="fas fa-times"></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {listProduk && listProduk.length === 0 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: 18,
                          }}
                        >
                          <b>Data Tidak Ditemukan</b>
                        </div>
                      ) : (
                        listProduk &&
                        listProduk.map((val) => {
                          return (
                            <Card
                              style={{
                                margin: 10,
                                width: 200,
                                boxShadow: "0px 2px 3.84px rgba(0, 0, 0, 0.25)",
                                elevation: 5,
                              }}
                            >
                              <Card.Body
                                style={{ padding: "0px 0px 10px 0px" }}
                              >
                                <img
                                  onClick={() => {
                                    val.fotoProduk.length === 0
                                      ? null
                                      : setModalImage(true);
                                    setUrlImage(val.fotoProduk[0]);
                                  }}
                                  alt="..."
                                  src={
                                    val.fotoProduk.length === 0
                                      ? require("assets/img/noImage.jpg")
                                          .default
                                      : val.fotoProduk[0]
                                  }
                                  style={{
                                    objectFit: "cover",
                                    display: "block",
                                    width: "100%",
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5,
                                    cursor:
                                      val.fotoProduk.length === 0
                                        ? "default"
                                        : "pointer",
                                  }}
                                ></img>
                                <div style={{ padding: "10px 15px 10px 15px" }}>
                                  <div
                                    style={{ fontSize: 16, fontWeight: "bold" }}
                                  >
                                    {val.kodeProduk} | {val.tipe}
                                  </div>
                                  <div style={{ fontSize: 14 }}>
                                    {val.namaProduk} | {val.kapasitas}
                                  </div>
                                  <div style={{ fontSize: 14 }}>
                                    <b>{formatCurrency(val.hargaProduk)}</b>
                                  </div>
                                  <div style={{ fontSize: 14 }}>
                                    {val.stockSales} Stock
                                  </div>
                                </div>
                              </Card.Body>
                              <Card.Footer
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    tambahKeKeranjang({
                                      idProduk: val.idProduk,
                                      sumberProduk: "SG",
                                    });
                                  }}
                                >
                                  + Keranjang
                                </Button>
                                <Button
                                  style={{
                                    width: "100%",
                                    backgroundColor:
                                      val.stockSales === 0
                                        ? "rgb(135,203,21)"
                                        : "rgb(216,216,216)",
                                    borderColor:
                                      val.stockSales === 0
                                        ? "rgb(135,203,21)"
                                        : "rgb(216,216,216)",
                                    color:
                                      val.stockSales === 0
                                        ? "rgb(255,255,255)"
                                        : "rgb(136,136,136)",
                                  }}
                                  onClick={() => {
                                    val.stockSales === 0
                                      ? tambahKeKeranjang({
                                          idProduk: val.idProduk,
                                          sumberProduk: "PL",
                                        })
                                      : null;
                                  }}
                                >
                                  Pesan Langsung
                                </Button>
                              </Card.Footer>
                            </Card>
                          );
                        })
                      )}
                    </div>

                    {/* <DataTable
                columns={columns}
                data={listProduk}
                //   pagination
                customStyles={customStyles}
                paginationPerPage={totalPerHalaman}
                //   onChangePage={handlePageChange}
                progressPending={pending}
              /> */}

                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  fontSize: 14,
                                }}
                              >
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={produk.listProduk.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="actives"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <hr style={{ borderWidth: 5 }}></hr>
          <Container fluid>
            <Row
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Col>
                <div style={{ fontSize: 25, fontWeight: "bold" }}>
                  Produk Upgrade
                </div>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <InputGroup>
                      <Form.Control
                        style={{
                          paddingLeft: "10px",
                          width: "300px",
                        }}
                        type="text"
                        value={searchTextUpgrade}
                        onChange={handleSearchUpgrade}
                        placeholder="Nama Mesin/Kode Mesin"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleClearSearchUpgrade}
                        >
                          <i class="fas fa-times"></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                {/* <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(40,118,74,1)",
                  borderColor: "rgba(40,118,74,1)",
                }}
                onClick={() => {
                  keranjang.length === 0
                    ? notify({
                        code: 1,
                        message:
                          "Maaf anda belum memiliki produk pada keranjang belanja",
                      })
                    : setShowKeranjang(true);
                }}
              >
                <i
                  className="fas fa-dolly-flatbed"
                  style={{ marginRight: 10 }}
                ></i>
                Keranjang
                <Badge
                  style={{
                    backgroundColor: "red",
                    fontSize: 12,
                    borderRadius: 10,
                    width: 20,
                    height: 20,
                    position: "absolute",
                    right: 5,
                    top: -10,
                  }}
                >
                  {keranjang.length}
                </Badge>
              </Button> */}
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <InputGroup>
                      <Form.Control
                        style={{
                          paddingLeft: "10px",
                          width: "300px",
                        }}
                        type="text"
                        value={searchText}
                        onChange={handleSearch}
                        placeholder="Nama Mesin/Kode Mesin"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleClearSearch}
                        >
                          <i class="fas fa-times"></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div> */}
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {listProdukUpgrade && listProdukUpgrade.length === 0 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: 18,
                          }}
                        >
                          <b>Data Tidak Ditemukan</b>
                        </div>
                      ) : (
                        listProdukUpgrade &&
                        listProdukUpgrade.map((val) => {
                          return (
                            <Card
                              style={{
                                margin: 10,
                                width: 200,
                                boxShadow: "0px 2px 3.84px rgba(0, 0, 0, 0.25)",
                                elevation: 5,
                              }}
                            >
                              <Card.Body
                                style={{ padding: "0px 0px 10px 0px" }}
                              >
                                <img
                                  onClick={() => {
                                    val.fotoProduk.length === 0
                                      ? null
                                      : setModalImage(true);
                                    setUrlImage(val.fotoProduk[0]);
                                  }}
                                  alt="..."
                                  src={
                                    val.fotoProduk.length === 0
                                      ? require("assets/img/noImage.jpg")
                                          .default
                                      : val.fotoProduk[0]
                                  }
                                  style={{
                                    objectFit: "cover",
                                    display: "block",
                                    width: "100%",
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5,
                                    cursor:
                                      val.fotoProduk.length === 0
                                        ? "default"
                                        : "pointer",
                                  }}
                                ></img>
                                <div style={{ padding: "10px 15px 10px 15px" }}>
                                  <div
                                    style={{ fontSize: 16, fontWeight: "bold" }}
                                  >
                                    {val.kodeProduk} | {val.tipe}
                                  </div>
                                  <div style={{ fontSize: 14 }}>
                                    {val.namaProduk} | {val.kapasitas}
                                  </div>
                                  <div style={{ fontSize: 14 }}>
                                    <b>{formatCurrency(val.hargaProduk)}</b>
                                  </div>
                                  <div style={{ fontSize: 14 }}>
                                    {val.stockSales} Stock
                                  </div>
                                </div>
                              </Card.Body>
                              <Card.Footer
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    tambahKeKeranjangUpgrade({
                                      sumberProduk: "SG",
                                      idProduk: val.idProduk,
                                    });
                                  }}
                                >
                                  + Keranjang
                                </Button>
                                <Button
                                  style={{
                                    width: "100%",
                                    backgroundColor:
                                      val.stockSales === 0
                                        ? "rgb(135,203,21)"
                                        : "rgb(216,216,216)",
                                    borderColor:
                                      val.stockSales === 0
                                        ? "rgb(135,203,21)"
                                        : "rgb(216,216,216)",
                                    color:
                                      val.stockSales === 0
                                        ? "rgb(255,255,255)"
                                        : "rgb(136,136,136)",
                                  }}
                                  onClick={() => {
                                    val.stockSales === 0
                                      ? tambahKeKeranjangUpgrade({
                                          idProduk: val.idProduk,
                                          sumberProduk: "PL",
                                        })
                                      : null;
                                  }}
                                >
                                  Pesan Langsung
                                </Button>
                              </Card.Footer>
                            </Card>
                          );
                        })
                      )}
                    </div>

                    {/* <DataTable
                columns={columns}
                data={listProduk}
                //   pagination
                customStyles={customStyles}
                paginationPerPage={totalPerHalaman}
                //   onChangePage={handlePageChange}
                progressPending={pending}
              /> */}

                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  fontSize: 14,
                                }}
                              >
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={optionsUpgrade.find(
                                  (option) =>
                                    option.value === totalPerHalamanUpgrade
                                )}
                                onChange={handlePageSizeChangeUpgrade}
                                options={optionsUpgrade}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={pageUpgrade}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={produk.listProdukUpgrade.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="actives"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={pageUpgrade}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPageUpgrade(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {/* Modal Image Produk */}
      <Modal
        size="md"
        show={modalImage}
        onHide={() => {
          setModalImage(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Body>
              <img
                alt="..."
                src={urlImage}
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              ></img>
            </Card.Body>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default CardProduk;
