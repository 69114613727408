const initialState = {
  listKaryawan: [],
  listKaryawanDropDown: [],
};

const karyawanReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLKARYAWAN":
      return {
        ...state,
        listKaryawan: data,
      };
    case "SET_ALLKARYAWANDROPDOWN":
      return {
        ...state,
        listKaryawanDropDown: data,
      };
    default:
      return state;
  }
};
export default karyawanReducer;
