import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getJabatan(dispatch, data, history) {
  try {
    const response = await baseAxios("/jabatanWeb/getJabatan", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLJABATAN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahJabatan(data) {
  try {
    const response = await baseAxios.post("/jabatanWeb/addJabatan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateJabatan(data) {
  try {
    const response = await baseAxios.patch("/jabatanWeb/updateJabatan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteJabatan(data) {
  try {
    const response = await baseAxios.patch("/jabatanWeb/deleteJabatan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
