import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ubahPassword } from "stores";
import { getProfile, ubahFotoProfile } from "stores";
import Swal from "sweetalert2";

function UserPage() {
  const auth = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const [listProfile, setListProfile] = React.useState(null);
  const [modalGantiFoto, setModalGantiFoto] = React.useState(false);
  const [modalUbahPass, setModalUbahPass] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const fileInputRef = React.useRef(null);
  const [fotoProfile, setFotoProfile] = React.useState(null);
  const [passwordBaru, setPasswordBaru] = React.useState("");
  const [passwordLama, setPasswordLama] = React.useState("");

  React.useEffect(() => {
    let tmp = auth.profile;
    setListProfile(tmp);
  }, [auth.profile]);

  React.useEffect(() => {
    getProfile(dispatch, {
      idUser: auth.responLogin && auth.responLogin.idUser,
    });
  }, [auth.responLogin]);

  const gantiFoto = async (e) => {
    e.preventDefault();
    setPending(true);
    const formData = new FormData();
    formData.append("fotoProfile", fotoProfile);
    await ubahFotoProfile(formData).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setModalGantiFoto(false);
        getProfile(dispatch, {
          idUser: auth.responLogin && auth.responLogin.idUser,
        });

        Swal.fire({
          title: "Sukses",
          text: response.data.message,
          icon: "success",
          showConfirmButton: true,
          // timer: 1500,
        });
        window.location.reload();
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data ? "Gagal import produk" : response.data.message,
        });
      }
    });
  };

  const simpan = async (e) => {
    e.preventDefault();
    setPending(true);
    await ubahPassword({
      passwordLama: passwordLama,
      passwordBaru: passwordBaru,
    }).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setModalUbahPass(false);
        localStorage.removeItem("token");
        window.location.replace("/auth/login-page");
        Swal.fire({
          title: "Sukses",
          text: response.data.message,
          icon: "success",
          showConfirmButton: true,
          // timer: 1500,
        });
        window.location.reload();
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data ? "Gagal import produk" : response.data.message,
        });
      }
    });
  };
  return (
    <>
      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Container>
            <Row>
              <Col md="8" sm="6">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">Profile</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Nama Lengkap</label>
                            <Form.Control
                              defaultValue="Creative Code Inc."
                              disabled
                              placeholder="Company"
                              type="text"
                              value={listProfile && listProfile.namaLengkap}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="6">
                          <Form.Group>
                            <label>Nama Panggilan</label>
                            <Form.Control
                              defaultValue="michael23"
                              placeholder="Username"
                              type="text"
                              disabled
                              value={listProfile && listProfile.namaPanggilan}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>First Name</label>
                            <Form.Control
                              defaultValue="Mike"
                              placeholder="Company"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Last Name</label>
                            <Form.Control
                              defaultValue="Andrew"
                              placeholder="Last Name"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>No Telp</label>
                            <Form.Control
                              defaultValue="michael23"
                              placeholder="Username"
                              type="text"
                              disabled
                              value={listProfile && listProfile.noTelp}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Email address
                            </label>
                            <Form.Control
                              placeholder="Email"
                              type="email"
                              disabled
                              value={listProfile && listProfile.email}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Jabatan</label>
                            <Form.Control
                              defaultValue="Mike"
                              placeholder="City"
                              type="text"
                              disabled
                              value={
                                listProfile && listProfile.jabatan.namaJabatan
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="6">
                          <Form.Group>
                            <label>Divisi</label>
                            <Form.Control
                              defaultValue="Andrew"
                              placeholder="Country"
                              type="text"
                              disabled
                              value={
                                listProfile && listProfile.divisi.namaDivisi
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label>Alamat</label>
                            <Form.Control
                              defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                              placeholder="Home Address"
                              type="text"
                              disabled
                              value={listProfile && listProfile.alamatKtp}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* <Row>
                        <Col md="12">
                          <Form.Group>
                            <label>About Me</label>
                            <Form.Control
                              cols="80"
                              defaultValue="Mike"
                              placeholder="Here can be your description"
                              rows="4"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row> */}
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setModalUbahPass(true);
                        }}
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                      >
                        Ubah Password
                      </Button>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
              <Col md="4">
                <Card className="card-user">
                  <Card.Header className="no-padding">
                    <div className="card-image">
                      <img
                        alt="..."
                        src={
                          require("assets/img/full-screen-image-3.jpg").default
                        }
                      ></img>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="author">
                      <a
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setModalGantiFoto(true);
                        }}
                      >
                        {/* <img
                          alt="Foto Profil"
                          className="avatar border-gray"
                          src={
                            (listProfile && !listProfile.fotoProfile) ||
                            (listProfile && listProfile.fotoProfile === "")
                              ? require("assets/img/default-avatar.png").default
                              : listProfile && listProfile.fotoProfile
                          }
                        /> */}
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={
                            (listProfile && !listProfile.fotoProfile) ||
                            (listProfile && listProfile.fotoProfile === "")
                              ? "https://i.ibb.co/9rxMHRy/user5.png"
                              : listProfile && listProfile.fotoProfile
                          }
                        ></img>
                        <Card.Title as="h5">
                          {listProfile && listProfile.namaLengkap}
                        </Card.Title>
                      </a>
                      <p className="card-description">
                        {" "}
                        {listProfile && listProfile.namaPanggilan}
                      </p>
                    </div>
                    <p className="card-description text-center">
                      {listProfile && listProfile.jabatan.namaJabatan} <br></br>
                      {listProfile && listProfile.divisi.namaDivisi}
                    </p>
                  </Card.Body>

                  {/* <Card.Footer>
                    <hr></hr>
                    <div className="button-container text-center">
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </Button>
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-twitter"></i>
                      </Button>
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-google-plus-square"></i>
                      </Button>
                    </div>
                  </Card.Footer> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      <Modal
        size="md"
        show={modalGantiFoto}
        onHide={() => {
          pending ? null : setModalGantiFoto(false);
          setFotoProfile(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Ganti Foto Profile
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Control
                ref={fileInputRef}
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => {
                  setFotoProfile(e.target.files[0]);
                  // setSizeFile(e.target.files[0].size / 1000000);
                }}
              ></Form.Control>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                disabled={fotoProfile === null || pending ? true : false}
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={gantiFoto}
              >
                {pending ? "Loading..." : "Submit"}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalUbahPass}
        onHide={() => {
          pending ? null : setModalUbahPass(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Ubah Password
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Password Lama
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setPasswordLama(e.target.value);
                    }}
                    placeholder="Password Lama"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Password Baru
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setPasswordBaru(e.target.value);
                    }}
                    placeholder="Password Baru"
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                disabled={
                  passwordBaru === "" || passwordLama === "" || pending
                    ? true
                    : false
                }
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={simpan}
              >
                {pending ? "Loading..." : "Ubah"}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default UserPage;
