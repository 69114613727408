const initialState = {
  listInvSj: [],
};

const suratJalanReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLINVSJ":
      return {
        ...state,
        listInvSj: data,
      };
    default:
      return state;
  }
};
export default suratJalanReducer;
