import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
  Row,
  Image,
} from "react-bootstrap";

function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  return (
    <>
      <div
        // className="full-page section-image"
        // data-color="black"
        style={{ overflow: "hidden" }}
        data-image={require("assets/img/Automation.jpeg").default}
      >
        <div
          className="content align-items-center"
          style={{ paddingTop: "0vh" }}
        >
          <Row>
            <Col lg="6" className="d-none d-lg-inline">
              <Row className="justify-content-center">
                {/* <Col style={{ paddingTop: "5vh" }} lg={{ offset: 2, span: 10 }}>
                  <Image
                    src={require("assets/img/LOGOBARU.png").default}
                    width="100"
                    height="auto"
                  />{" "}
                </Col> */}
                {/* <Col
                lg={{ span: 10, offset: 2 }}
                style={{
                  color: "#000000",
                  fontSize: 70,
                  fontFamily: "sans-serif",
                  paddingTop: "10vh",
                }}
                > */}
                <Image
                  src={require("assets/img/Computerlogin.png").default}
                  width="700"
                  height="auto"
                />{" "}
                {/* </Col> */}
                {/* <Col lg={{ span: 10, offset: 2 }}>
                  <i
                    style={{ color: "#000000", fontSize: 20 }}
                  >{`Raja Pengering`}</i>
                  <br />
                 
                </Col> */}
              </Row>
            </Col>

            <Col lg="5" className="pt-2 pt-lg-5  d-flex justify-content-center">
              <div className="w-100 p-0 p-lg-5">
                <Form
                  action=""
                  className="form"
                  method=""
                  style={{ margin: 20 }}
                >
                  <div>
                    <Row>
                      <Col md="3">
                        <Image
                          src={require("assets/img/LOGOBARU.png").default}
                          width="100"
                          height="auto"
                        />
                      </Col>
                      <Col style={{ marginLeft: 20 }}>
                        <div style={{ fontSize: 40 }}>
                          <b>{`BackOffice`}</b>
                        </div>
                        <div style={{ fontSize: 20, color: "grey" }}>
                          <i>
                            <b>{`Raja Pengering`}</b>
                          </i>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Card
                    className={"card-login " + cardClasses}
                    style={{ border: "0px" }}
                  >
                    <Card.Header style={{ color: "#000000" }}>
                      <h3 className="header " style={{ fontWeight: 900 }}>
                        Login
                      </h3>
                    </Card.Header>
                    <Card.Body>
                      <Form.Group>
                        <label style={{ color: "#000000" }}>
                          <b>No Karyawan</b>
                        </label>
                        <Form.Control
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          placeholder="Enter No Karyawan"
                          type="email"
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label style={{ color: "#000000" }}>
                          <b>Password</b>
                        </label>
                        <Form.Control
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          placeholder="Password"
                          type="password"
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="ml-auto">
                      <Container>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            login(
                              dispatch,
                              { nokar: username, password: password },
                              history
                            );
                          }}
                          className="btn-wd"
                          type="submit"
                          // variant="info"
                          style={{
                            backgroundColor: "#407BFF",
                            borderColor: "#407BFF",
                          }}
                        >
                          Login
                        </Button>
                      </Container>
                    </Card.Footer>
                  </Card>
                </Form>
              </div>
            </Col>
            {/* </Container> */}
            {/* </Col> */}
          </Row>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundColor: "white",
            // backgroundImage:
            //   "url(" + require("assets/img/Automation.jpeg").default + ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;

{
  /* <div className="content d-flex align-items-center p-0">
          <Col>
            <Container style={{ marginTop: 200 }}>
              <Row className="justify-content-md-center">
                <Col md="auto">
                  <Image
                    src={"https://backoffice.bapguard.com/upload/IFM.png"}
                    width="1100"
                    height="auto"
                  />
                </Col>
                <div style={{ marginLeft: 50, marginTop: 100 }}>
                  <Col>
                    <Image
                      src={"https://backoffice.bapguard.com/upload/logobap.png"}
                      width="250"
                      height="auto"
                    />
                  </Col>
                </div>
              </Row>
            </Container>


            <Container style={{ marginTop: 200 }}>
              <Col className="mx-auto" lg="12" md="4">
                <Form action="" className="form" method="">
                  <Row>
                    <Col sm="5">
                      <Form.Group>
                      
                        <Form.Control
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          placeholder="Enter Username"
                          type="email"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm="5">
                      <Form.Group>
                   
                        <Form.Control
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          placeholder="Password"
                          type="password"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Button
                        onClick={() => {
                          login(dispatch, { username, password }, history);
                        }}
                        className="btn-wd"
                        type="submit"
                        variant="warning"
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Container>
           
          </Col>
        </div>  */
}
