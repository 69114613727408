const initialState = {
  listPl: [],
};

const pesanLangsungReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_PESANLANGSUNG":
      return {
        ...state,
        listPl: data,
      };
    default:
      return state;
  }
};
export default pesanLangsungReducer;
